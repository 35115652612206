// CONSTANTS
import { colors } from 'constants/colors'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  columnStatus: {
    display: 'flex',
    alignItems: 'center',
  },
  columnStatusCircle: {
    marginRight: 8,
    height: 10,
    width: 10,
    borderRadius: '50%',
  },
  columnStatusCircleRed: {
    backgroundColor: colors.red2,
  },
  columnStatusCircleGreen: {
    backgroundColor: colors.green3,
  },
  columnStatusText: {
    fontSize: 'inherit',
    fontWeight: 'inherit',
    textTransform: 'capitalize',
  },
}))

export default useStyles