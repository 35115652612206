// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconCanbus = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M6.16668 3.66667C6.16668 3.20834 5.79168 2.83334 5.33334 2.83334C4.87501 2.83334 4.50001 3.20834 4.50001 3.66667V7H2.83334V15.3333C2.83334 16.4167 3.53334 17.3333 4.50001 17.6833V21.1667H6.16668V17.6833C7.13334 17.3333 7.83334 16.4167 7.83334 15.3333V7H6.16668V3.66667ZM5.33334 16.1667C4.87501 16.1667 4.50001 15.7917 4.50001 15.3333V13.6667H6.16668V15.3333C6.16668 15.7917 5.79168 16.1667 5.33334 16.1667ZM4.50001 12V8.66667H6.16668V12H4.50001ZM12.8333 3.66667C12.8333 3.20834 12.4583 2.83334 12 2.83334C11.5417 2.83334 11.1667 3.20834 11.1667 3.66667V7H9.50001V15.3333C9.50001 16.4167 10.2 17.3333 11.1667 17.6833V21.1667H12.8333V17.6833C13.8 17.3333 14.5 16.4167 14.5 15.3333V7H12.8333V3.66667ZM12 16.1667C11.5417 16.1667 11.1667 15.7917 11.1667 15.3333V13.6667H12.8333V15.3333C12.8333 15.7917 12.4583 16.1667 12 16.1667ZM11.1667 12V8.66667H12.8333V12H11.1667ZM19.5 7V3.66667C19.5 3.20834 19.125 2.83334 18.6667 2.83334C18.2083 2.83334 17.8333 3.20834 17.8333 3.66667V7H16.1667V15.3333C16.1667 16.4167 16.8667 17.3333 17.8333 17.6833V21.1667H19.5V17.6833C20.4667 17.3333 21.1667 16.4167 21.1667 15.3333V7H19.5ZM18.6667 16.1667C18.2083 16.1667 17.8333 15.7917 17.8333 15.3333V13.6667H19.5V15.3333C19.5 15.7917 19.125 16.1667 18.6667 16.1667ZM17.8333 12V8.66667H19.5V12H17.8333Z"/>
    </SvgIcon>
  )
}

export default IconCanbus