// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: 8,
  },
  toggleButtonGroupDivider: {
    width: 1,
    backgroundColor: theme.palette.divider,
  },
}))

export default useStyles