// CONSTANTS
import { colors, entLacakColors, iotColors } from 'constants/colors'
import { values } from 'constants/values'

// MUIS
import { createTheme } from '@mui/material/styles'

const getPaletteColors = () => {
  if (values.dashboardType === values.dashboardTypePpa) {
    return {
      primary: {
        main: colors.red1,
      },
      error: {
        main: entLacakColors.error,
      },
    }
  }
  else if (values.dashboardType === values.dashboardTypeIot) {
    return {
      primary: iotColors.primary,
      secondary: iotColors.secondary,
      error: {
        main: entLacakColors.error,
      },
    }
  }
}

const customTheme = createTheme({
  palette: getPaletteColors(),
  typography: {
    fontFamily: [
      'Open Sans', 'sans-serif',
    ].join(','),
  },
})

export default customTheme