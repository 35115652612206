import React from 'react'

// APEXCHART
import ReactApexChart from 'react-apexcharts'

// COMPONENTS
import LoadingBox from 'components/LoadingBox/LoadingBox'

// CONTSTANTS
import { colors } from 'constants/colors'

// DATA
import { 
  statisticsReportCardList, 
  statisticsReportChartSettings,
} from '../dashboardData'

// MUIS
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconArrowDownward from '@mui/icons-material/ArrowDownward'
import IconArrowUpward from '@mui/icons-material/ArrowUpward'

// STYLES
import useStyles from './cardStatisticsReportUseStyles'

// UTILS
import { getAllDatesFrom2Dates } from 'utils/dateRange'

const CardStatisticsReport = (props) => {
  const { dateRangeValue } = props

  const classes = useStyles()

  const xAxisCategories = getAllDatesFrom2Dates(dateRangeValue[0], dateRangeValue[1])

  return (
    <LoadingBox className={classes.root}>
      {/* TITLE */}
      <Typography
        variant='subtitle1'
        className={`${classes.title} ${classes.textBold}`}
      >
        Statistic Report of Each Metric
      </Typography>

      {/* REPORT CARDS */}
      <Grid 
        container
        columnSpacing={'16px'}
      >
        {statisticsReportCardList(xAxisCategories.length).map((item, index) => (
          <Grid
            key={index}
            item
            xs={3}
          >
            {/* CARD ITEM */}
            <Box 
              className={classes.cardItem}
              sx={{
                borderBottom: `6px solid ${item.color} !important`, 
              }}
            >
              {/* HEADER */}
              <Box className={classes.cardItemHeader}>
                {/* TITLE */}
                <Typography
                  variant='body2'
                  className={classes.textBold}
                >
                  {item.title}
                </Typography>

                {/* CHANGES */}
                <Typography
                  variant='body2'
                  className={`${classes.cardItemChanges} ${classes.textBold}`}
                  sx={{ color: item.changes > 0 ? colors.green4 : colors.red2 }}
                >
                  {`${item.changes > 0 ? '+' : ''}${item.changes}%`}
                </Typography>

                {/* ARROW INDICATOR */}
                {item.changes > 0 ? 
                <IconArrowUpward className={`${classes.cardItemArrow} ${classes.cardItemArrowGreen}`}/> : 
                <IconArrowDownward className={`${classes.cardItemArrow} ${classes.cardItemArrowRed}`}/>}
              </Box>

              {/* VALUE TEXT */}
              <Typography
                variant='h6'
                className={classes.textBold}
              >
                {`${item.value} DT`}
              </Typography>

              {/* LAST TIMESTAMP */}
              <Typography
                variant='body2'
                className={classes.cardItemLastTimestamp}
              >
                {item.lastTimestamp}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>

      {/* CHART */}
      <Box className={classes.chartContainer}>
        <ReactApexChart 
          options={statisticsReportChartSettings(xAxisCategories).options} 
          series={statisticsReportChartSettings(xAxisCategories).series} 
          type='line' 
          height={350} 
        />
      </Box>
    </LoadingBox>
  )
}

export default CardStatisticsReport