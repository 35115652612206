const vehiclesTableData = [
	{
		"id": 1,
		"name": "R5K 4R6",
		"licenseNumber": "HBG67COA8JM",
		"site": "sociis",
		"mileage": 7733,
		"hourMeter": "23:33",
		"lastUpdate": "Sep 20, 2021",
		"place": "pretium",
		"status": false
	},
	{
		"id": 2,
		"name": "H8V 3R3",
		"licenseNumber": "PDL67CTN2QJ",
		"site": "in,",
		"mileage": 4230,
		"hourMeter": "1:21",
		"lastUpdate": "Jun 26, 2022",
		"place": "vitae",
		"status": true
	},
	{
		"id": 3,
		"name": "J7L 9B0",
		"licenseNumber": "MXX18ZTO5DB",
		"site": "eu",
		"mileage": 5160,
		"hourMeter": "17:05",
		"lastUpdate": "Feb 9, 2022",
		"place": "dolor,",
		"status": false
	},
	{
		"id": 4,
		"name": "H1C 8O6",
		"licenseNumber": "GBE22RTH8MT",
		"site": "blandit.",
		"mileage": 7621,
		"hourMeter": "23:55",
		"lastUpdate": "Dec 13, 2022",
		"place": "ultrices.",
		"status": true
	},
	{
		"id": 5,
		"name": "D2X 6M6",
		"licenseNumber": "ESY23AUT0ZM",
		"site": "erat.",
		"mileage": 8970,
		"hourMeter": "13:37",
		"lastUpdate": "Jan 24, 2022",
		"place": "Donec",
		"status": false
	},
  {
		"id": 6,
		"name": "Z4I 1S4",
		"licenseNumber": "GDK43TYN9VD",
		"site": "penatibus",
		"mileage": 6258,
		"hourMeter": "7:44",
		"lastUpdate": "Jan 21, 2023",
		"place": "amet",
		"status": false
	},
	{
		"id": 7,
		"name": "W7R 5U2",
		"licenseNumber": "XEO31TYG5WH",
		"site": "auctor",
		"mileage": 2823,
		"hourMeter": "12:43",
		"lastUpdate": "Nov 5, 2022",
		"place": "ut",
		"status": false
	},
	{
		"id": 8,
		"name": "B9L 5U1",
		"licenseNumber": "WDU83BOD8FQ",
		"site": "magna.",
		"mileage": 9672,
		"hourMeter": "5:30",
		"lastUpdate": "Jul 8, 2022",
		"place": "Duis",
		"status": false
	},
	{
		"id": 9,
		"name": "G1A 3U4",
		"licenseNumber": "BKW17OEN3XN",
		"site": "adipiscing.",
		"mileage": 9216,
		"hourMeter": "21:08",
		"lastUpdate": "Feb 25, 2023",
		"place": "libero.",
		"status": false
	},
	{
		"id": 10,
		"name": "J2C 7C5",
		"licenseNumber": "JRQ65CDP3HW",
		"site": "Sed",
		"mileage": 4335,
		"hourMeter": "7:35",
		"lastUpdate": "Aug 19, 2022",
		"place": "sociosqu",
		"status": true
	},
  {
		"id": 11,
		"name": "Z4I 1S4",
		"licenseNumber": "GDK43TYN9VD",
		"site": "penatibus",
		"mileage": 6258,
		"hourMeter": "7:44",
		"lastUpdate": "Jan 21, 2023",
		"place": "amet",
		"status": false
	},
	{
		"id": 12,
		"name": "W7R 5U2",
		"licenseNumber": "XEO31TYG5WH",
		"site": "auctor",
		"mileage": 2823,
		"hourMeter": "12:43",
		"lastUpdate": "Nov 5, 2022",
		"place": "ut",
		"status": false
	},
	{
		"id": 13,
		"name": "B9L 5U1",
		"licenseNumber": "WDU83BOD8FQ",
		"site": "magna.",
		"mileage": 9672,
		"hourMeter": "5:30",
		"lastUpdate": "Jul 8, 2022",
		"place": "Duis",
		"status": false
	},
	{
		"id": 14,
		"name": "G1A 3U4",
		"licenseNumber": "BKW17OEN3XN",
		"site": "adipiscing.",
		"mileage": 9216,
		"hourMeter": "21:08",
		"lastUpdate": "Feb 25, 2023",
		"place": "libero.",
		"status": false
	},
	{
		"id": 15,
		"name": "J2C 7C5",
		"licenseNumber": "JRQ65CDP3HW",
		"site": "Sed",
		"mileage": 4335,
		"hourMeter": "7:35",
		"lastUpdate": "Aug 19, 2022",
		"place": "sociosqu",
		"status": true
	},
  {
		"id": 16,
		"name": "Z4I 1S4",
		"licenseNumber": "GDK43TYN9VD",
		"site": "penatibus",
		"mileage": 6258,
		"hourMeter": "7:44",
		"lastUpdate": "Jan 21, 2023",
		"place": "amet",
		"status": false
	},
	{
		"id": 17,
		"name": "W7R 5U2",
		"licenseNumber": "XEO31TYG5WH",
		"site": "auctor",
		"mileage": 2823,
		"hourMeter": "12:43",
		"lastUpdate": "Nov 5, 2022",
		"place": "ut",
		"status": false
	},
	{
		"id": 18,
		"name": "B9L 5U1",
		"licenseNumber": "WDU83BOD8FQ",
		"site": "magna.",
		"mileage": 9672,
		"hourMeter": "5:30",
		"lastUpdate": "Jul 8, 2022",
		"place": "Duis",
		"status": false
	},
	{
		"id": 19,
		"name": "G1A 3U4",
		"licenseNumber": "BKW17OEN3XN",
		"site": "adipiscing.",
		"mileage": 9216,
		"hourMeter": "21:08",
		"lastUpdate": "Feb 25, 2023",
		"place": "libero.",
		"status": false
	},
	{
		"id": 20,
		"name": "J2C 7C5",
		"licenseNumber": "JRQ65CDP3HW",
		"site": "Sed",
		"mileage": 4335,
		"hourMeter": "7:35",
		"lastUpdate": "Aug 19, 2022",
		"place": "sociosqu",
		"status": true
	},
  {
		"id": 21,
		"name": "Z4I 1S4",
		"licenseNumber": "GDK43TYN9VD",
		"site": "penatibus",
		"mileage": 6258,
		"hourMeter": "7:44",
		"lastUpdate": "Jan 21, 2023",
		"place": "amet",
		"status": false
	},
	{
		"id": 22,
		"name": "W7R 5U2",
		"licenseNumber": "XEO31TYG5WH",
		"site": "auctor",
		"mileage": 2823,
		"hourMeter": "12:43",
		"lastUpdate": "Nov 5, 2022",
		"place": "ut",
		"status": false
	},
	{
		"id": 23,
		"name": "B9L 5U1",
		"licenseNumber": "WDU83BOD8FQ",
		"site": "magna.",
		"mileage": 9672,
		"hourMeter": "5:30",
		"lastUpdate": "Jul 8, 2022",
		"place": "Duis",
		"status": false
	},
	{
		"id": 24,
		"name": "G1A 3U4",
		"licenseNumber": "BKW17OEN3XN",
		"site": "adipiscing.",
		"mileage": 9216,
		"hourMeter": "21:08",
		"lastUpdate": "Feb 25, 2023",
		"place": "libero.",
		"status": false
	},
	{
		"id": 25,
		"name": "J2C 7C5",
		"licenseNumber": "JRQ65CDP3HW",
		"site": "Sed",
		"mileage": 4335,
		"hourMeter": "7:35",
		"lastUpdate": "Aug 19, 2022",
		"place": "sociosqu",
		"status": true
	},
]

export default vehiclesTableData