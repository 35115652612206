import React from 'react'

// CUSTOM COMPONENTS
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'

// MUIS
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Slide from '@mui/material/Slide'

// MUI ICONS
import IconList from '@mui/icons-material/List'

// STYLES
import useStyles from './panelDeviceToggleUseStyles'

const PanelDeviceToggle = (props) => {
  const {
    isDevicePanelShown, 
    setIsDevicePanelShown,
    pageRef,
  } = props

  const classes = useStyles()

  return (
    <Slide
      direction='right' 
      in={!isDevicePanelShown} 
      container={pageRef.current}
    >
      <Box className={classes.root}>
        <CustomTooltipBlack
          title='Devices'
          placement='bottom'
        >
          <IconButton onClick={() => setIsDevicePanelShown(true)}>
            <IconList/>
          </IconButton>
        </CustomTooltipBlack>
      </Box>
    </Slide>
  )
}

export default PanelDeviceToggle