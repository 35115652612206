// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconFuelReport = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M19.1225 7.6275L19.1316 7.61833L15.7216 4.20833L14.75 5.18L16.6841 7.11417C15.8225 7.44417 15.2083 8.26917 15.2083 9.25C15.2083 10.515 16.235 11.5417 17.5 11.5417C17.83 11.5417 18.1325 11.4683 18.4166 11.3492V17.9583C18.4166 18.4625 18.0041 18.875 17.5 18.875C16.9958 18.875 16.5833 18.4625 16.5833 17.9583V13.8333C16.5833 12.825 15.7583 12 14.75 12H13.8333V5.58333C13.8333 4.575 13.0083 3.75 12 3.75H6.49996C5.49163 3.75 4.66663 4.575 4.66663 5.58333V20.25H13.8333V13.375H15.2083V17.9583C15.2083 19.2233 16.235 20.25 17.5 20.25C18.765 20.25 19.7916 19.2233 19.7916 17.9583V9.25C19.7916 8.6175 19.535 8.04 19.1225 7.6275ZM12 13.375V18.4167H6.49996V12H12V13.375ZM12 10.1667H6.49996V5.58333H12V10.1667ZM17.5 10.1667C16.9958 10.1667 16.5833 9.75417 16.5833 9.25C16.5833 8.74583 16.9958 8.33333 17.5 8.33333C18.0041 8.33333 18.4166 8.74583 18.4166 9.25C18.4166 9.75417 18.0041 10.1667 17.5 10.1667Z"/>
    </SvgIcon>
  )
}

export default IconFuelReport