// MUIS
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'

const CustomDialogActionButton = styled(({ className, ...props }) => (
  <Button
    className={className} 
    {...props}
  />
))(({ theme }) => ({
  height: 36,
  width: 88,
  textTransform: 'uppercase',
  fontWeight: 600,
}))

export default CustomDialogActionButton