// CONSTANTS
import { colors } from 'constants/colors'

// MUI STYLES
import { alpha } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  downloadMenu: {
    transform: 'translateX(-16px)',
  },
  columnCN: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 40,
    padding: '4px 8px',
    backgroundColor: alpha(colors.blue1, 0.1),
    cursor: 'pointer',
  },
  columnCNIcon: {
    marginRight: 4,
    width: 20,
    height: 20,
  },
  columnCNText: {
    color: colors.blue1,
    fontSize: 'inherit',
    fontWeight: 'inherit',
  },
}))

export default useStyles