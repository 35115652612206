// CONSTANTS
import { colors } from 'constants/colors'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  contentHeaderRoot: {
    display: 'flex',
    height: 70,
    alignItems: 'center',
    flexShrink: 0,
  },
  title: {
    fontWeight: 600,
    marginLeft: 16,
  },
  iconDownload: {
    margin: '0px 4px',
    marginLeft: 'auto',
  },
  iconButton: {
    margin: '0px 4px',
    '&:last-child': {
      marginRight: 8,
    },
  },
  iconAction: {
    color: colors.iconDark,
  },
  selectedDay: {
    width: 50,
    textAlign: 'center',
  },
}))

export default useStyles