const dashboardType = process.env.REACT_APP_DASHBOARD_TYPE
const dashboardTypePpa = 'PPA'
const dashboardTypeIot = 'IOT'

const getSiteOptionList = () => {
  if (dashboardType === dashboardTypePpa) return [ 'MHU', 'BIB', 'KJB', 'RUB', 'SKS', 'AHM' ]
  else if (dashboardType === dashboardTypeIot) return [ 'Site-A', 'Site-B', 'Site-C', 'Site-D', 'Site-E', 'Site-F', ]
}

export const values = {
  drawerWidth: 256,
  drawerWidthOnMinimized: 72,
  siteOptionList: getSiteOptionList(),
  initialSnackbarObject: {
    open: false,
    severity: 'success',
    message: ''
  },
  baseApiUrlPpa: process.env.REACT_APP_PPA_API_BASE_URL,
  apiRequestInterval: process.env.REACT_APP_API_REQUEST_INTERVAL, // IN MILISECONDS
  dashboardType: dashboardType,
  dashboardTypePpa: dashboardTypePpa,
  dashboardTypeIot: dashboardTypeIot,
}