import React, { useEffect } from 'react'

// ECHARTS
import { init, getInstanceByDom } from 'echarts'

// MUIS
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

// STYLES
import useStyles from './chartUseStyles'

// UTILS
import { getChartOption } from 'utils/fuelReportUtil'

const Chart = (props) => {
  const { 
    chartRef,
    chartData, 
  } = props

  const classes = useStyles()

  useEffect(() => {
    let chart
    if (chartRef.current !== null) chart = init(chartRef.current)

    const resizeChart = () => {
      chart?.resize()
    }
    window.addEventListener('resize', resizeChart)

    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current)
      chart.setOption(getChartOption(
        chartData.fuelConsumedDetails.map(item => item.deviceName),
        chartData.fuelConsumedDetails.map(item => item.fuelReportPerHour),
      ))
    }

    return () => {
      chart?.dispose()
      window.removeEventListener('resize', resizeChart)
    }
  }, [chartData])

  return (
    <Box className={classes.chartRoot}>
      {/* TITLE */}
      <Typography 
        variant='subtitle1'
        className={classes.title}
      >
        Average
      </Typography>

      {/* AVERAGE VALUE */}
      <Typography
        variant='h5'
        className={classes.averageUsage}
      >
        {`${chartData.average} L/h`}
      </Typography>

      {/* AREA GRADIENT CHART */}
      <Box className={classes.chartContainer}>
        <Box
          ref={chartRef}
          className={classes.chart}
        />
      </Box>
    </Box>
  )
}

export default Chart