import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

// COMPONENTS
import LoadingBox from 'components/LoadingBox/LoadingBox'
import DataGridFilters from 'components/DataGridFilters/DataGridFilters'
import PageHeader from 'components/PageHeader/PageHeader'

// CUSTOM COMPONENTS
import CustomDataGridPro from 'components/Customs/CustomDataGridPro'

// MUIS
import Box from '@mui/material/Box'

// SERVICES
import { 
  getCycleList,
  formatCycleListOutput, 
} from 'services/routesServices'

// STYLES
import useStyles from 'styles/layoutMainPageUseStyles'

// UTILS
import {
  getStartDateFromLocalStorage,
  getEndDateFromLocalStorage,
} from 'utils/localStorage'
import { exportCycleListToExcel } from 'utils/routesUtil'

const RoutesDetail = () => {
  const classes = useStyles()

  const params = useParams()

  const initialDateRangeValue = [ 
    getStartDateFromLocalStorage(), 
    getEndDateFromLocalStorage() 
  ]

  const initialColumns = [
    {
      field: 'cyclePortTimestamp',
      headerName: 'Date (GMT+8)',
      flex: 1,
      minWidth: 175,
    },
    {
      field: 'payload',
      headerName: 'Pld',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'emptySpeed',
      headerName: 'ES',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'loadedSpeed',
      headerName: 'LS',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'speed',
      headerName: 'Speed',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'loadingTime',
      headerName: 'LT',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'loadedDriveTime',
      headerName: 'LDT',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'loadedStopTime',
      headerName: 'LST',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'emptyDriveTime',
      headerName: 'EDT',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'emptyStopTime',
      headerName: 'EST',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'cycleTime',
      headerName: 'CT',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'loadedDriveDistance',
      headerName: 'LDD',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'emptyDriveDistance',
      headerName: 'EDD',
      flex: 1,
      minWidth: 100,
    },
  ]

  const [ dateRangeValue, setDateRangeValue ] = useState(initialDateRangeValue)
  const [ deviceName, setDeviceName ] = useState('')
  const [ search, setSearch ] = useState('')
  const [ tableData, setTableData ] = useState([])
  const [ filteredTableData, setFilteredTableData ] = useState([])
  const [ isLoading, setIsLoading ] = useState(false)
  const [ selectedColumnList, setSelectedColumnList ] = useState(initialColumns)

  const handleDownloadIconButtonClick = () => {
    exportCycleListToExcel(
      deviceName,
      tableData,
      dateRangeValue[0].getTime(), 
      dateRangeValue[1].getTime(),
      params.shift,
    ) 
  }

  const loadCycleListData = async () => {
    setIsLoading(true)

    const resultCycleList = await getCycleList(
      dateRangeValue[0].getTime(),
      dateRangeValue[1].getTime(),
      params.deviceId, 
      params.shift,
    )

    if(resultCycleList.status === 200) {
      const formatedCycleList = formatCycleListOutput(resultCycleList.data)
      setDeviceName(formatedCycleList[0].deviceName)
      setTableData(formatedCycleList[0].cycles)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    loadCycleListData()
  }, [dateRangeValue])

  useEffect(() => {
    if(search !== '') {
      const newTableData = tableData.filter(item => {
        if(
          item.cyclePortTimestamp.toLowerCase().includes(search.toLowerCase()) ||
          String(item.payload).includes(search) ||
          String(item.emptySpeed).includes(search) ||
          String(item.loadedSpeed).includes(search) ||
          String(item.speed).includes(search) ||
          item.loadingTime.includes(search) ||
          item.loadedDriveTime.includes(search) ||
          item.loadedStopTime.includes(search) ||
          item.emptyDriveTime.includes(search) ||
          item.emptyStopTime.includes(search) ||
          item.cycleTime.includes(search) ||
          String(item.loadedDriveDistance).includes(search) ||
          String(item.emptyDriveDistance).includes(search)
        ) return item
      })
      setFilteredTableData(newTableData)
    }
  }, [search])
  
  return (
    <Box className={classes.pageRoot}>
      {/* PAGE HEADER */}
      <PageHeader
        isAddButtonAvailable={false}
        onAddButtonIsClicked={null}
        isBackButtonAvailable={true}
        targetBackUrl='/routes'
        title='Device Detail'
        isSearchAvailable={true}
        search={search}
        setSearch={setSearch} 
        isExtraComponentAvailable={false}
        extraComponent={null}
      />

      {/* CONTENT */}
      <LoadingBox isLoading={isLoading}>
        {/* DATA GRID FILTERS */}
        <DataGridFilters
          isWithTabs={false}
          title={deviceName}
          selectedTab={null}
          setSelectedTab={null}
          tabItemList={null}
          handleDownloadIconButtonClick={handleDownloadIconButtonClick}
          isWithCustomizeColumn={true}
          columns={initialColumns}
          selectedColumnList={selectedColumnList}
          setSelectedColumnList={setSelectedColumnList}
          isWithDateRange={true}
          dateRangeValue={dateRangeValue}
          setDateRangeValue={setDateRangeValue}
        />

        {/* TABLE */}
        <CustomDataGridPro
          columns={selectedColumnList}
          rows={search === '' ? tableData : filteredTableData}
        />
      </LoadingBox>
    </Box>
  )
}

export default RoutesDetail