// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconDrawerToggle = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M10.5899 5.58934C10.9153 5.2639 10.9153 4.73626 10.5899 4.41083C10.2645 4.08539 9.73683 4.08539 9.4114 4.41083L4.4114 9.41083C4.09591 9.72631 4.08488 10.2343 4.38636 10.5632L8.96969 15.5632C9.28068 15.9025 9.80782 15.9254 10.1471 15.6144C10.4864 15.3034 10.5093 14.7762 10.1983 14.437L6.1541 10.0251L10.5899 5.58934Z"/>
      <path opacity="0.3" d="M16.4226 5.58928C16.748 5.26384 16.748 4.7362 16.4226 4.41076C16.0972 4.08533 15.5695 4.08533 15.2441 4.41076L10.2441 9.41077C9.9286 9.72625 9.91756 10.2342 10.219 10.5631L14.8024 15.5631C15.1134 15.9024 15.6405 15.9253 15.9798 15.6143C16.319 15.3033 16.342 14.7762 16.031 14.4369L11.9868 10.0251L16.4226 5.58928Z"/>
    </SvgIcon>
  )
}

export default IconDrawerToggle