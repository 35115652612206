import React, { useState} from 'react'

// COMPONENTS
import ChartLogEvents from './ChartLogEvents/ChartLogEvents'
import ChartTrips from './ChartTrips/ChartTrips'
import LoadingBox from 'components/LoadingBox/LoadingBox'
import ContentHeader from './ContentHeader/ContentHeader'
import PageHeader from 'components/PageHeader/PageHeader'
import StatusTable from './StatusTable/StatusTable'

// MUIS
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'

// STYLES
import useStyles from './vehiclesDetailUseStyles'

const VehiclesDetail = () => {
  const classes = useStyles()

  const [ isLoading, setIsLoading ] = useState(false)

  return (
    <Box className={classes.pageRoot}>
      {/* HEADER */}
      <PageHeader
        isAddButtonAvailable={false}
        onAddButtonIsClicked={null}
        isBackButtonAvailable={true}
        targetBackUrl='/vehicles'
        title='Vehicle Detail'
        isSearchAvailable={false}
        search={null}
        setSearch={null} 
        isExtraComponentAvailable={false}
        extraComponent={null}
      />

      {/* CONTENT */}
      <LoadingBox 
        className={classes.content}
        isLoading={isLoading}
      >
        {/* CONTENT HEADER */}
        <ContentHeader/>

        <Divider/>

        {/* TRIPS CHART */}
        <ChartTrips/>

        {/* LOG EVENTS CHART */}
        <ChartLogEvents/>

        {/* STATUS TABLE */}
        <StatusTable/>
      </LoadingBox>
    </Box>
  )
}

export default VehiclesDetail