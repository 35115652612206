import React from 'react'

// MUIS
import Box from '@mui/material/Box'

// STYLES
import useStyles from './authenticationUseStyles'

const Authentication = (props) => {
  const { children } = props

  const classes = useStyles()

  return (
    <Box className={`${classes.authenticationRoot} no-zoom`}>
      <Box className={`${classes.authenticationContent} zoom`}>
        {children}
      </Box>
    </Box>
  )
}

export default Authentication