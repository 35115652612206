import React from 'react'

// CUSTOM COMPONENTS
import CustomToggleButtonGroup from 'components/Customs/CustomToggleButtonGroup'

// MUIS
import Box from '@mui/material/Box'
import ToggleButton from '@mui/material/ToggleButton'

// MUI ICONS
import IconStackedLineChart from '@mui/icons-material/StackedLineChart'
import IconViewList from '@mui/icons-material/ViewList'

// STYLES
import useStyles from './changeViewUseStyles'

const ChangeView = (props) => {
  const { 
    selectedView,
    setSelectedView,
  } = props

  const classes = useStyles()

  const handleViewModeToggleButtonGroupChange = (event, newValue) => {
    if(newValue) setSelectedView(newValue)
  }

  return (
    <CustomToggleButtonGroup
      size='small'
      value={selectedView}
      exclusive
      onChange={handleViewModeToggleButtonGroupChange}
      color='primary'
      className={classes.root}
    >
      {/* CHART MODE */}
      <ToggleButton value='chart'>
        <IconStackedLineChart/>
      </ToggleButton>

      <Box
        component='div'
        className={classes.toggleButtonGroupDivider}
      />

      {/* TABLE MODE */}
      <ToggleButton value='table'>
        <IconViewList/>
      </ToggleButton>
    </CustomToggleButtonGroup>
  )
}

export default ChangeView