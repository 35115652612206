import React from 'react'

// APEXCHART
import ReactApexChart from 'react-apexcharts'

// COMPONENTS
import LoadingBox from 'components/LoadingBox/LoadingBox'

// DATA
import { 
  columnChartList, 
  columnChartOptions,
} from '../dashboardData'

// MUIS
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

// STYLES
import useStyles from './cardsColumnChartUseStyles'

// UTILS
import { getAllDatesFrom2Dates } from 'utils/dateRange'

const CardsColumnChart = (props) => {
  const { dateRangeValue } = props
  
  const classes = useStyles()

  const xAxisCategories = getAllDatesFrom2Dates(dateRangeValue[0], dateRangeValue[1])

  return (
    <Grid 
      container
      spacing={'24px'}
      className={classes.root}
    >
      {columnChartList.map((item, index) => (
        <Grid
          key={index}
          item
          xs={6}
        >
          {/* CARD ITEM */}
          <LoadingBox className={classes.cardItem}>
            {/* TITLE */}
            <Typography
              variant='subtitle1'
              className={classes.cardItemTitle}
            >
              {item.title}
            </Typography>

            {/* CHART */}
            <ReactApexChart 
              options={columnChartOptions(item.title, item.color, xAxisCategories).options} 
              series={columnChartOptions(item.title, item.color, xAxisCategories).series} 
              type='bar' 
              height={280} 
            />
          </LoadingBox>
        </Grid>
      ))}
    </Grid>
  )
}

export default CardsColumnChart