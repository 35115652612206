export const statusTableData = [
  {
		"id": 1,
		"status": "ON",
		"start": "13:46:29",
		"duration": "12:48:07",
		"place": "IP"
	},
	{
		"id": 2,
		"status": "OFF",
		"start": "3:47:45",
		"duration": "18:35:45",
		"place": "LCKT"
	},
	{
		"id": 3,
		"status": "OFF",
		"start": "14:50:11",
		"duration": "19:51:14",
		"place": "Workshop"
	},
	{
		"id": 4,
		"status": "ON",
		"start": "7:12:41",
		"duration": "1:57:42",
		"place": "BKS"
	},
	{
		"id": 5,
		"status": "DR",
		"start": "10:13:40",
		"duration": "3:22:26",
		"place": "LCKT"
	},
  {
		"id": 6,
		"status": "ON",
		"start": "10:52:38",
		"duration": "11:35:19",
		"place": "BKS"
	},
	{
		"id": 7,
		"status": "OFF",
		"start": "5:35:28",
		"duration": "23:25:31",
		"place": "LCKT"
	},
	{
		"id": 8,
		"status": "DR",
		"start": "17:50:32",
		"duration": "13:35:14",
		"place": "BKS"
	},
	{
		"id": 9,
		"status": "ON",
		"start": "22:50:22",
		"duration": "18:54:42",
		"place": "LCKT"
	},
	{
		"id": 10,
		"status": "DR",
		"start": "13:09:36",
		"duration": "5:57:41",
		"place": "BKS"
	},
]