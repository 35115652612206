// MUIS
import { grey } from '@mui/material/colors'

export const colors = {
  blue1: '#4C9CEE',
  blue2: '#1976D2',
  blue3: '#4B6496',
  green1: '#83CD3D',
  green2: '#81C784',
  green3: '#59AE85',
  green4: '#4EAC5C',
  grey: '#F2F3F7',
  icon: grey[500], // GRAY
  iconDark: grey[600], // DARK GRAY
  orange1: '#EF6C00',
  orange2: '#FF8A65',
  purple: '#9575CD',
  red1: '#EB0523', // PRIMARY
  red2: '#F64E60',
  red3: '#E57373',
  yellow1: '#FFC105',
  yellowGreen: '#8BC34A', 
}

export const entLacakColors = {
  backgroundPage: '#EBEBEB', // LIGHT GRAY
  error: '#FF5252', // RED,
  silver: '#CCCCCC', // GRAY
}

export const iotColors = {
  // BLUE
  primary: {
    main: '#0D50A0',
    light: '#86BAF8',
    dark: '#143E70',
  },
  // LIGHT GREEN
  secondary: {
    main: '#95C14F',
    light: '#D8FF99',
    dark: '#D8FF99',
  },
}