// APIS
import axios from 'apis/axios'

// SERVICES
import { postRefreshToken } from './tokenServices'

export const getTrackerList = async () => {
  const resultRefreshToken = await postRefreshToken()

  if(resultRefreshToken.status === 200) {
    try {
      const response = await axios.get(
        'tracker/dev/list',
        { 
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${resultRefreshToken.data.accessToken}`,
          },
        },
      )
  
      return response
    }
    catch (error) {
      if (!error.response) return { status: 'No Server Response' }
      else return error.response
    }
  }
}

export const getDeviceStateList = async () => {
  const resultRefreshToken = await postRefreshToken()

  if(resultRefreshToken.status === 200) {
    try {
      const response = await axios.get(
        'tracker/dev/get_states',
        { 
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${resultRefreshToken.data.accessToken}`,
          },
        },
      )
  
      return response
    }
    catch (error) {
      if (!error.response) return { status: 'No Server Response' }
      else return error.response
    }
  }
}