import React, { useState, useEffect } from 'react'

// APEXCHARTS
import ReactApexChart from 'react-apexcharts'

// COMPONENTS
import LoadingBox from 'components/LoadingBox/LoadingBox'

// CONTSTANTS
import { colors } from 'constants/colors'

// DATA
import { 
  overallCardList, 
  overallPieChartConfig,
} from '../dashboardData'

// MUIS
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { alpha } from '@mui/material/styles'

// MUI ICONS
import IconArrowDownward from '@mui/icons-material/ArrowDownward'
import IconArrowUpward from '@mui/icons-material/ArrowUpward'

// SERVICES
import { 
  getAvailabilityValue, 
  formatAvailabilityValueOutput,
  getUsabilityValue,
  formatUsabilityValueOutput,
} from 'services/dashboardServices'

// STYLES
import useStyles from './cardsOverallUseStyles'

const CardsOverall = (props) => {
  const { dateRangeValue } = props

  const [ availabityValue, setAvailabilityValue ] = useState(0)
  const [ usabilityValue, setUsabilityValue ] = useState(0)
  const [ isLoading, setIsLoading ] = useState(false)

  const classes = useStyles()

  const lastCardList = [
    {
      title: 'Availability',
      value: availabityValue,
    },
    {
      title: 'Usability',
      value: usabilityValue,
    },
  ]

  const loadAvailabilityValue = async () => {
    setIsLoading(true)

    const resultAvailability = await getAvailabilityValue(
      dateRangeValue[0].getTime() / 1000, 
      dateRangeValue[1].getTime() / 1000
    )

    if (resultAvailability.status === 200) {
      setAvailabilityValue(formatAvailabilityValueOutput(resultAvailability.data))
    }

    setIsLoading(false)
  }

  const loadUsabilityValue = async () => {
    setIsLoading(true)

    const resultUsability = await getUsabilityValue(
      dateRangeValue[0].getTime() / 1000, 
      dateRangeValue[1].getTime() / 1000
    )

    if (resultUsability.status === 200) {
      setUsabilityValue(formatUsabilityValueOutput(resultUsability.data))
    }

    setIsLoading(false)
  }

  useEffect(() => {
    loadAvailabilityValue()
    loadUsabilityValue()
  }, [dateRangeValue])

  return (
    <Grid 
      container
      columnSpacing={'24px'}
    >
      {/* FIRST 3 CARDS */}
      {overallCardList.map((item, index) => (
        <Grid
          key={index}
          item
          xs={3}
        >
          {/* CARD ITEM */}
          <LoadingBox className={classes.cardItem}>
            {/* HEADER */}
            <Box className={classes.header}>
              {/* ICON */}
              <Box 
                className={classes.iconContainer}
                sx={{ backgroundColor: alpha(item.color, 0.1) }}
              >
                <item.icon 
                  className={classes.icon}
                  sx={{ color: item.color }}
                />
              </Box>

              {/* TITLE */}
              <Typography
                variant='subtitle1'
                className={classes.title}
              >
                {item.title}
              </Typography>

              {/* CHANGES */}
              <Typography
                variant='subtitle2'
                className={classes.fontBold}
                sx={{ color: item.changes > 0 ? colors.green4 : colors.red2 }}
              >
                {`${item.changes > 0 ? '+' : ''}${item.changes}%`}
              </Typography>

              {/* ARROW INDICATOR */}
              {item.changes > 0 ? 
              <IconArrowUpward className={`${classes.arrow} ${classes.arrowGreen}`}/> : 
              <IconArrowDownward className={`${classes.arrow} ${classes.arrowRed}`}/>}
            </Box>

            {/* VALUE */}
            <Typography
              variant='h5'
              className={classes.fontBold}
            >
              {`${item.value} DT`}
            </Typography>

            {/* LAST TIMESTAMP */}
            <Typography
              variant='body2'
              className={classes.lastTimestamp}
            >
              {item.lastTimestamp}
            </Typography>
          </LoadingBox>
        </Grid>
      ))}

      {/* LAST CARD */}
      <Grid
        item
        xs={3}
      >
        {/* CARD ITEM */}
        <LoadingBox 
          className={`${classes.cardItem} ${classes.cardItemLast}`}
          isLoading={isLoading}
        >
          {lastCardList.map((item, index) => (
            <Box 
              className={classes.cardItemHalf}
              key={index}
            >
              {/* TITLE */}
              <Typography
                variant='subtitle1'
                className={classes.fontBold}
              >
                {item.title}
              </Typography>

              {/* CHART */}
              <ReactApexChart
                options={overallPieChartConfig}
                series={[ item.value ]}
                type='radialBar'
                // width={'100%'}
                // height={'100%'}
              />
            </Box>
          ))}
        </LoadingBox>
      </Grid>
    </Grid>
  )
}

export default CardsOverall