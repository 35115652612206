import React, { useState } from 'react'
import PropTypes from 'prop-types'

// CUSTOM COMPONENTS
import CustomInput from '../Customs/CustomInput'
import CustomTooltipBlack from '../Customs/CustomTooltipBlack'

// MUI CORES
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'

// MUI ICONS
import IconClose from '@mui/icons-material/Close'
import IconSearch from '@mui/icons-material/Search'

// STYLES
import useStyles from './pageSearchFieldUseStyles'

const PageSearchField = (props) => {
  const {
    value,
    onChange,
  } = props

  const [ isSearchOpen, setIsSearchOpen ] = useState(false)

  const classes = useStyles()

  return (
    <Box className={classes.searchFieldRoot}>
      {/* SEARCH ICON */}
      <CustomTooltipBlack
        title='Search'
        placement='bottom'
      >
        <IconButton 
          className={classes.iconSearch}
          onClick={() => setIsSearchOpen(true)}
        >
          <IconSearch/>
        </IconButton>
      </CustomTooltipBlack>

      {/* TEXT FIELD */}
      <CustomInput 
        className={
          isSearchOpen ?
          classes.customSearchInputWide :
          classes.customSearchInputNarrow
        }
        placeholder='Search'
        value={value}
        onChange={onChange}
        endAdornment={
          // ICON CLOSE
          <InputAdornment position='end'>
            <IconClose
              onClick={() => setIsSearchOpen(false)}
              className={classes.iconClose}
            />
          </InputAdornment>
        }
      />
    </Box>
  )
}

PageSearchField.defaultProps = {
  value: '',
}

PageSearchField.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default PageSearchField
