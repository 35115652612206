import XLSX from 'xlsx'

// UTILS
import { convertTimeStampToDateOnly } from 'utils/date'

export const exportPayloadListToExcel = (
  inputPayloadListData,
  inputBeginTime,
  inputEndTime,
) => {
  const sheetPayloadListData = inputPayloadListData.map((item, index) => {
    return {
      'No': index + 1,
      'Date': item.date,
      'DT Code': item.dtCode,
      'Cycle Start GMT+7': item.cycleStartGMT7,
      'Port': item.port,
      'Jam Timbang GMT+7': item.weighingHoursGMT7,
      'Jam Timbang GMT+8': item.weighingHoursGMT8,
      'Unit Loading': item.unitLoading,
      'Ton System': item.payload,
    }
  })

  const sheetPayload = XLSX.utils.json_to_sheet(sheetPayloadListData)
  
  const workBook = XLSX.utils.book_new()

  XLSX.utils.book_append_sheet(workBook, sheetPayload, 'Payload')

  XLSX.writeFile(workBook, `PPA Payload ${convertTimeStampToDateOnly(inputBeginTime)} - ${convertTimeStampToDateOnly(inputEndTime)}.xlsx`)
}