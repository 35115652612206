// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconInsight = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M16.4122 9.38444C16.8326 9.71017 16.9094 10.315 16.5836 10.7354L13.9399 14.1475C13.7828 14.3503 13.5513 14.482 13.2967 14.5134C13.0422 14.5448 12.7856 14.4734 12.5839 14.315L10.2671 12.4951L8.15868 15.2355C7.83438 15.657 7.22979 15.7357 6.8083 15.4114C6.38681 15.0871 6.30802 14.4826 6.63232 14.0611L9.3339 10.5498C9.49066 10.3461 9.72237 10.2135 9.97744 10.1816C10.2325 10.1496 10.4898 10.221 10.6919 10.3798L13.0112 12.2017L15.0613 9.55585C15.387 9.13546 15.9918 9.05872 16.4122 9.38444Z"/>
      <path d="M18.9021 4.32583C18.4757 4.32583 18.1301 4.67148 18.1301 5.09786C18.1301 5.52424 18.4757 5.86988 18.9021 5.86988C19.3285 5.86988 19.6741 5.52424 19.6741 5.09786C19.6741 4.67148 19.3285 4.32583 18.9021 4.32583ZM16.2042 5.09786C16.2042 3.60785 17.4121 2.39996 18.9021 2.39996C20.3921 2.39996 21.6 3.60785 21.6 5.09786C21.6 6.58786 20.3921 7.79575 18.9021 7.79575C17.4121 7.79575 16.2042 6.58786 16.2042 5.09786Z"/>
      <path d="M3.83932 4.77017C4.80149 3.73229 6.16845 3.15964 7.76653 3.15964H16.3628C16.8947 3.15964 17.3258 3.59076 17.3258 4.12257C17.3258 4.65439 16.8947 5.08551 16.3628 5.08551H7.76653C6.6465 5.08551 5.81172 5.47535 5.25166 6.07948C4.68397 6.69184 4.32597 7.60417 4.32597 8.76566V16.0607C4.32597 17.2271 4.67852 18.1361 5.24022 18.7438C5.79345 19.3423 6.6261 19.7326 7.76653 19.7326H15.5325C16.6539 19.7326 17.4887 19.344 18.0482 18.7419C18.6152 18.1319 18.9731 17.2221 18.9731 16.0607L18.9731 16.0564L19.011 7.69154C19.0134 7.15973 19.4464 6.73057 19.9783 6.73297C20.5101 6.73538 20.9392 7.16845 20.9368 7.70026L20.899 16.0607V16.0627C20.8986 17.6187 20.4138 19.0256 19.4589 20.053C18.4962 21.0888 17.1293 21.6585 15.5325 21.6585H7.76653C6.1558 21.6585 4.78671 21.0904 3.82598 20.0511C2.87372 19.0209 2.4001 17.6125 2.4001 16.0607V8.76566C2.4001 7.20904 2.88479 5.79982 3.83932 4.77017Z"/>
    </SvgIcon>
  )
}

export default IconInsight