import React, { createContext, useState } from 'react'

// CONSTANTS
import { values } from 'constants/values'

const MainLayoutContext = createContext()

const MainLayoutContextProvider = (props) => {
  const [ isDrawerExpanded, setIsDrawerExpanded ] = useState(true)
  const [ snackbarObject, setSnackbarObject ] = useState(values.initialSnackbarObject)

  const changeIsDrawerExpanded = (inputState) => {
    setIsDrawerExpanded(inputState)
  }

  const changeSnackbarObject = (inputObject) => {
    setSnackbarObject(inputObject)
  }

  return (
    <MainLayoutContext.Provider
      value={{
        isDrawerExpanded, changeIsDrawerExpanded,
        snackbarObject, changeSnackbarObject,
      }}
    >
      {props.children}
    </MainLayoutContext.Provider>
  )
}

export { MainLayoutContextProvider, MainLayoutContext }