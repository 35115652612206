import React, { useState, useEffect } from 'react'

// COMPONENTS
import LoadingBox from 'components/LoadingBox/LoadingBox'
import DataGridFilters from 'components/DataGridFilters/DataGridFilters'
import PageHeader from 'components/PageHeader/PageHeader'

// CUSTOM COMPONENTS
import CustomDataGridPro from 'components/Customs/CustomDataGridPro'

// MUIS
import Box from '@mui/material/Box'

// SERVICES
import { 
  getPayloadList,
  formatPayloadListOutput, 
} from 'services/payloadServices'

// STYLES
import useStyles from 'styles/layoutMainPageUseStyles'

// UTILS
import {
  getStartDateFromLocalStorage,
  getEndDateFromLocalStorage,
} from 'utils/localStorage'
import { exportPayloadListToExcel } from 'utils/payloadUtil'

const Payload = () => {
  const classes = useStyles()

  const initialDateRangeValue = [ 
    getStartDateFromLocalStorage(), 
    getEndDateFromLocalStorage() 
  ]

  const initialColumns = [
    {
      field: 'date',
      headerName: 'Date',
      flex: 1,
      minWidth: 125,
    },
    {
      field: 'dtCode',
      headerName: 'DT Code',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'cycleStartGMT7',
      headerName: 'Cycle Start GMT+7',
      flex: 1,
      minWidth: 175,
    },
    {
      field: 'port',
      headerName: 'Port',
      flex: 1,
      minWidth: 75,
    },
    {
      field: 'weighingHoursGMT7',
      headerName: 'Jam Timbang GMT+7',
      flex: 1,
      minWidth: 175,
    },
    {
      field: 'weighingHoursGMT8',
      headerName: 'Jam Timbang GMT+8',
      flex: 1,
      minWidth: 175,
    },
    {
      field: 'unitLoading',
      headerName: 'Unit Loading',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'payload',
      headerName: 'Ton System',
      flex: 1,
      minWidth: 100,
    },
  ]

  const [ dateRangeValue, setDateRangeValue ] = useState(initialDateRangeValue)
  const [ search, setSearch ] = useState('')
  const [ tableData, setTableData ] = useState([])
  const [ filteredTableData, setFilteredTableData ] = useState([])
  const [ isLoading, setIsLoading ] = useState(false)
  const [ selectedColumnList, setSelectedColumnList ] = useState(initialColumns)

  const handleDownloadIconButtonClick = () => {
    exportPayloadListToExcel(
      tableData,
      dateRangeValue[0].getTime(), 
      dateRangeValue[1].getTime(),
    )
  }

  const loadPayloadListData = async () => {
    setIsLoading(true)

    const resultPayloadList = await getPayloadList(
      dateRangeValue[0].getTime(),
      dateRangeValue[1].getTime(),
    )

    if(resultPayloadList.status === 200) {
      const formatedPayloadList = formatPayloadListOutput(resultPayloadList.data)
      setTableData(formatedPayloadList)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    loadPayloadListData()
  }, [dateRangeValue])

  useEffect(() => {
    if(search !== '') {
      const newTableData = tableData.filter(item => {
        if(
          item.date.toLowerCase().includes(search.toLowerCase()) ||
          item.dtCode.toLowerCase().includes(search.toLowerCase()) ||
          item.cycleStartGMT7.toLowerCase().includes(search.toLowerCase()) ||
          item.port.toLowerCase().includes(search.toLowerCase()) ||
          item.weighingHoursGMT7.toLowerCase().includes(search.toLowerCase()) ||
          item.weighingHoursGMT8.toLowerCase().includes(search.toLowerCase()) ||
          item.unitLoading.toLowerCase().includes(search.toLowerCase()) ||
          String(item.payload).includes(search)
        ) return item
      })
      setFilteredTableData(newTableData)
    }
  }, [search])
  
  return (
    <Box className={classes.pageRoot}>
      {/* HEADER */}
      <PageHeader
        isAddButtonAvailable={false}
        onAddButtonIsClicked={null}
        isBackButtonAvailable={false}
        targetBackUrl={null}
        title='Payload'
        isSearchAvailable={true}
        search={search}
        setSearch={setSearch} 
        isExtraComponentAvailable={false}
        extraComponent={null}
      />

      {/* CONTENT */}
      <LoadingBox isLoading={isLoading}>
        {/* DATA GRID FILTERS */}
        <DataGridFilters
          isWithTabs={false}
          title={'Payload List'}
          selectedTab={null}
          setSelectedTab={null}
          tabItemList={null}
          handleDownloadIconButtonClick={handleDownloadIconButtonClick}
          isWithCustomizeColumn={true}
          columns={initialColumns}
          selectedColumnList={selectedColumnList}
          setSelectedColumnList={setSelectedColumnList}
          isWithDateRange={true}
          dateRangeValue={dateRangeValue}
          setDateRangeValue={setDateRangeValue}
        />

        {/* TABLE */}
        <CustomDataGridPro
          columns={selectedColumnList}
          rows={search === '' ? tableData : filteredTableData}
        />
      </LoadingBox>
    </Box>
  )
}

export default Payload