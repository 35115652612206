// COLORS
import { colors } from 'constants/colors'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  panelContainer: {
    backgroundColor: 'white',
    borderRadius: 4,
    width: 372,
    position: 'absolute',
    zIndex: 2,
    left: 416,
    top: 20,
    transform: 'translateX(0px)',
    transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: theme.shadows[4],
  },
  panelContainerMove: {
    transform: 'translateX(-332px)',
    transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
  },
  panelHeader: {
    display: 'flex',
    alignItems: 'center',
    height: 56,
  },
  panelTitle: {
    fontWeight: 600,
    marginLeft: 20,
    marginRight: 'auto',
  },
  iconClose: {
    width: 36,
    height: 36,
    marginRight: 12,
  },
  panelContent: {
    padding: '4px 20px',
  },
  contentLocation: {
    display: 'flex',
    alignItems: 'center',
    height: 41,
  },
  contentLocationIcon: {
    marginRight: 24,
    color: colors.icon,
  },
  contentLastUpdated: {
    color: colors.iconDark,
  },
  contentIndented: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 44,
  },
  contentBatteryAndSignal: {
    height: 32,
    display: 'flex',
    alignItems: 'center',
  },
  contentBatteryAndSignalIcon: {
    color: colors.yellowGreen,
    marginRight: 10,
  },
  contentAddress: {
    margin: '8px 0px',
    fontWeight: 600,
  },
  textSecondary: {
    color: colors.iconDark,
  },
  contentDetailInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 8,
    '&:last-child': {
      marginBottom: 16,
    },
  },
}))

export default useStyles