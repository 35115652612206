import React, { useState } from 'react'

// COMPONENTS
import CardsColumnChart from './CardsColumnChart/CardsColumnChart'
import CardsOverall from './CardsOverall/CardsOverall'
import CardStatisticsReport from './CardStatisticsReport/CardStatisticsReport'
import Header from './Header/Header'

// MUIS
import Box from '@mui/material/Box'

// STYELS
import pageStyles from 'styles/layoutMainPageUseStyles'
import useStyles from './dashboardUseStyles'

// UTILS
import {
  getStartDateFromLocalStorage,
  getEndDateFromLocalStorage,
} from 'utils/localStorage'

const Dashboard = () => {
  const pageClasses = pageStyles()
  const classes = useStyles()

  const initialDateRangeValue = [ 
    getStartDateFromLocalStorage(), 
    getEndDateFromLocalStorage() 
  ]

  const [ dateRangeValue, setDateRangeValue ] = useState(initialDateRangeValue)

  return (
    <Box className={`${pageClasses.pageRoot} ${classes.root}`}>
      {/* HEADER */}
      <Header
        dateRangeValue={dateRangeValue}
        setDateRangeValue={setDateRangeValue}
      />

      {/* OVERALL CARDS */}
      <CardsOverall dateRangeValue={dateRangeValue}/>

      {/* STATISTICS CARD */}
      <CardStatisticsReport dateRangeValue={dateRangeValue}/>

      {/* COLUMN CHART CARDS */}
      <CardsColumnChart dateRangeValue={dateRangeValue}/>
    </Box>
  )
}

export default Dashboard