// CONSTANTS
import { entLacakColors } from 'constants/colors'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  pageRoot: {
    backgroundColor: entLacakColors.backgroundPage,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: 24,
  },
  content: {
    flex: 1,
    display: 'unset',
    // flexDirection: 'column',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
}))

export default useStyles