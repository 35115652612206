// APIS
import axios from 'apis/axios'

// SERVICES
import { postRefreshToken } from './tokenServices'

// UTILS
import { 
  convertTimeStampToTimeStampAt12PM,
  convertValueTo1DecimalPoint, 
} from 'utils/date'

export const getFuelReportList = async (
  inputBeginTime, 
  inputEndTime,
  inputShift,
) => {
  const resultRefreshToken = await postRefreshToken()

  if(resultRefreshToken.status === 200) {
    try {
      let completeUrl = '/devices/all/fuelconsumed'
      completeUrl = `${completeUrl}?beginTs=${Math.round(convertTimeStampToTimeStampAt12PM(inputBeginTime) / 1000)}`
      completeUrl = `${completeUrl}&endTs=${Math.round(convertTimeStampToTimeStampAt12PM(inputEndTime) / 1000)}`
      completeUrl = `${completeUrl}&shift=${inputShift}`

      const response = await axios.get(
        completeUrl,
        { 
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${resultRefreshToken.data.accessToken}`,
          },
        },
      )
  
      return response
    }
    catch (error) {
      if (!error.response) return { status: 'No Server Response' }
      else return error.response
    }
  }
}

export const formatFuelReportListOutput = (inputResponseJson) => {
  const output = inputResponseJson.fuelConsumedDetails.map(item => {
    return {
      id: item.deviceId,
      fuelReportPerHour: convertValueTo1DecimalPoint(item.fuelConsumedPerHour),
      deviceName: item.deviceName,
      status: item.status,
      deviceId: item.deviceId,
    }
  })

  return {
    average: convertValueTo1DecimalPoint(inputResponseJson.fuelConsumedPerHourTotal),
    fuelConsumedDetails: output,
  }
}