// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconRoute = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M5.14273 14.6119V8.64737C5.14273 7.55221 5.57617 6.50191 6.34772 5.72752C7.11926 4.95312 8.16569 4.51807 9.25682 4.51807C10.3479 4.51807 11.3944 4.95312 12.1659 5.72752C12.9375 6.50191 13.3709 7.55221 13.3709 8.64737V15.0707C13.3709 15.6791 13.6117 16.2626 14.0403 16.6929C14.469 17.1231 15.0503 17.3648 15.6565 17.3648C16.2627 17.3648 16.844 17.1231 17.2727 16.6929C17.7013 16.2626 17.9421 15.6791 17.9421 15.0707V8.95018C17.3319 8.73371 16.8175 8.30767 16.49 7.74736C16.1624 7.18705 16.0428 6.52856 16.1522 5.88828C16.2616 5.24799 16.593 4.66715 17.0879 4.24841C17.5827 3.82967 18.2091 3.60001 18.8564 3.60001C19.5036 3.60001 20.13 3.82967 20.6248 4.24841C21.1197 4.66715 21.4511 5.24799 21.5605 5.88828C21.6699 6.52856 21.5503 7.18705 21.2227 7.74736C20.8952 8.30767 20.3808 8.73371 19.7706 8.95018V15.0707C19.7706 16.1659 19.3372 17.2162 18.5656 17.9906C17.7941 18.765 16.7476 19.2 15.6565 19.2C14.5654 19.2 13.519 18.765 12.7474 17.9906C11.9759 17.2162 11.5424 16.1659 11.5424 15.0707V8.64737C11.5424 8.03895 11.3016 7.45545 10.873 7.02523C10.4443 6.59501 9.863 6.35332 9.25682 6.35332C8.65064 6.35332 8.06928 6.59501 7.64065 7.02523C7.21202 7.45545 6.97121 8.03895 6.97121 8.64737V14.6119H9.71394L6.05697 19.2L2.4 14.6119H5.14273ZM18.8564 7.27094C19.0988 7.27094 19.3314 7.17426 19.5028 7.00217C19.6743 6.83008 19.7706 6.59668 19.7706 6.35332C19.7706 6.10995 19.6743 5.87655 19.5028 5.70446C19.3314 5.53237 19.0988 5.4357 18.8564 5.4357C18.6139 5.4357 18.3813 5.53237 18.2099 5.70446C18.0384 5.87655 17.9421 6.10995 17.9421 6.35332C17.9421 6.59668 18.0384 6.83008 18.2099 7.00217C18.3813 7.17426 18.6139 7.27094 18.8564 7.27094Z"/>
    </SvgIcon>
  )
}

export default IconRoute