// MUIS
import { alpha } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: alpha('#000000', 0.4),
    zIndex: theme.zIndex.modal - 1,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'absolute',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 500,
    height: '100%',
    backgroundColor: 'white',
  },
}))

export default useStyles