// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    zIndex: 2,
    backgroundColor: 'white',
    borderRadius: 4,
    left: 20,
    top: 20,
    width: 40,
    height: 40,
    display: 'flex',
    boxShadow: theme.shadows[4],
  },
}))

export default useStyles