// APIS
import axios from 'apis/axios'

// SERVICES
import { postRefreshToken } from './tokenServices'

// UTILS
import { 
  convertValueTo1DecimalPoint,
  convertTimeStampToDateAndTimeGMT7, 
  convertTimeStampToDateAndTimeGMT8,
  convertTimeStampToTimeStampAt12AM,
} from 'utils/date'

export const getPayloadList = async (
  inputBeginTime, 
  inputEndTime,
) => {
  const resultRefreshToken = await postRefreshToken()

  if(resultRefreshToken.status === 200) {
    try {
      let completeUrl = '/devices/all/payloadcycles'
      completeUrl = `${completeUrl}?beginTs=${Math.round(inputBeginTime / 1000)}`
      completeUrl = `${completeUrl}&endTs=${Math.round(convertTimeStampToTimeStampAt12AM(inputEndTime) / 1000) - 1}`

      const response = await axios.get(
        completeUrl,
        { 
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${resultRefreshToken.data.accessToken}`,
          },
        },
      )
  
      return response
    }
    catch (error) {
      if (!error.response) return { status: 'No Server Response' }
      else return error.response
    }
  }
}

export const formatPayloadListOutput = (inputResponseJson) => {
  const output = inputResponseJson.map((item, index) => {
    return {
      id: index + 1,
      date: item.date,
      dtCode: item.dtCode,
      cycleStartGMT7: convertTimeStampToDateAndTimeGMT7(item.cycleStartTimestampGMT7 * 1000),
      port: item.port,
      weighingHoursGMT7: convertTimeStampToDateAndTimeGMT7(item.weighingTimestampGMT7 * 1000),
      weighingHoursGMT8: convertTimeStampToDateAndTimeGMT8(item.weighingTimestampGMT7 * 1000),
      unitLoading: item.unitLoading,
      payload: convertValueTo1DecimalPoint(item.payload),
    }
  })
  return output
}