// APIS
import axios from 'apis/axios'

// SERVICES
import { postRefreshToken } from './tokenServices'

export const getCanbusList = async (
  inputBeginTime, 
  inputEndTime,
) => {
  const resultRefreshToken = await postRefreshToken()

  if(resultRefreshToken.status === 200) {
    try {
      let completeUrl = '/canbus/dev/get_all?page=0&offset=1000'
      completeUrl = `${completeUrl}&from=${inputBeginTime}`
      completeUrl = `${completeUrl}&to=${inputEndTime}`

      const response = await axios.get(
        completeUrl,
        { 
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${resultRefreshToken.data.accessToken}`,
          },
        },
      )
  
      return response
    }
    catch (error) {
      if (!error.response) return { status: 'No Server Response' }
      else return error.response
    }
  }
}