// APIS
import axios from 'apis/axios'

// SERVICES
import { postRefreshToken } from './tokenServices'

export const getAvailabilityValue = async (
  inputBeginTime, 
  inputEndTime,
) => {
  const resultRefreshToken = await postRefreshToken()

  if(resultRefreshToken.status === 200) {
    try {
      let completeUrl = '/devices/all/availabilitypercentage'
      completeUrl = `${completeUrl}?beginTs=${inputBeginTime}`
      completeUrl = `${completeUrl}&endTs=${inputEndTime}`

      const response = await axios.get(
        completeUrl,
        { 
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${resultRefreshToken.data.accessToken}`,
          },
        },
      )
  
      return response
    }
    catch (error) {
      if (!error.response) return { status: 'No Server Response' }
      else return error.response
    }
  }
}

export const formatAvailabilityValueOutput = (inputResponseJSON) => {
  const output = parseInt(inputResponseJSON.availabilityPercentage)
  return output
}

export const getUsabilityValue = async (
  inputBeginTime, 
  inputEndTime,
) => {
  const resultRefreshToken = await postRefreshToken()

  if(resultRefreshToken.status === 200) {
    try {
      let completeUrl = '/devices/all/usabilitypercentage'
      completeUrl = `${completeUrl}?beginTs=${inputBeginTime}`
      completeUrl = `${completeUrl}&endTs=${inputEndTime}`

      const response = await axios.get(
        completeUrl,
        { 
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${resultRefreshToken.data.accessToken}`,
          },
        },
      )
  
      return response
    }
    catch (error) {
      if (!error.response) return { status: 'No Server Response' }
      else return error.response
    }
  }
}

export const formatUsabilityValueOutput = (inputResponseJSON) => {
  const output = parseInt(inputResponseJSON.usabilityPercentage)
  return output
}