// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 16,
    height: 56,
  },
  title: {
    fontWeight: 600,
  },
  dateRangePicker: {
    marginLeft: 'auto !important',
    marginRight: 16,
    width: 200,
  },
  dateRangePickerIcon: {
    color: theme.palette.grey[600],
    cursor: 'pointer',
  },
  dateRangePickerDialog: {
    '& .MuiPaper-root': {
      maxWidth: 'unset',
    },
  },
}))

export default useStyles