// MUIS
import { grey } from '@mui/material/colors'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: 'fit-content',
  },
  leftPanelContainer: {
    width: 200,
    paddingRight: 24,
    borderRight: `1px solid ${grey[200]}`,
  },
  leftPanelItemButton: {
    padding: '8px 0px 7px 24px',
    height: 34.5,
    borderLeft: '4px solid transparent',
    '&:hover': {
      backgroundColor: grey[100],
    },
  },
  leftPanelItemButtonActive: {
    borderLeftColor: theme.palette.primary.main,
  },
  leftPanelItemText: {
    color: grey[500],
    '& .MuiTypography-root': {
      fontSize: 13,
    },
  },
  leftPanelItemDivider: {
    marginLeft: 28,
  },
  rightPanelContainer: {
    padding: '19px 24px 24px',
  },
  title: {
    color: grey[500],
    fontWeight: 600,
  },
  dateAndTimeInputContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 16,
  },
  dateAndTimeInput: {
    marginRight: 12,
    color: theme.palette.text.primary,
    fontWeight: 600,
    fontSize: 14,
    flex: 1,
  },
  dateAndTimeSelect: {
    marginRight: 12,
    flex: 1,
    '& .MuiSelect-select': {
      color: theme.palette.text.primary,
      fontWeight: 600,
      fontSize: 14,
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  startAndEndDivider: {
    width: 12,
    height: 2,
    marginRight: 12,
    backgroundColor: grey[600],
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  countDays: {
    marginRight: 'auto',
    fontSize: 12,
    color: grey[500],
  },
  actionButton: {
    marginLeft: 20,
    fontWeight: 600,
    width: 88,
  },
  actionButtonCancel: {
    color: grey[600],
  },
}))

export default useStyles
