// APIS
import axios from 'apis/axios'

export const postSignInUser = async (
  inputEmail, 
  inputPassword,
) => {
  try {
    const response = await axios.post(
      '/auth/login',
      JSON.stringify({
        email: inputEmail,
        password: inputPassword,
      }),
      { 
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )

    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}