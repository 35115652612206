import React from 'react'

// CUSTOM COMPONENTS
import CustomDataGridPro from 'components/Customs/CustomDataGridPro'

// DATA
import { statusTableData } from './statusTableData'

// MUIS
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

// STYLES
import useStyles from './statusTableUseStyles'

const StatusTable = () => {
  const classes = useStyles()

  const columns = [
    {
      field: 'status',
      headerName: 'Status Vehicle',
      flex: 1,
      minWidth: 200,
      sortable: false,
      renderCell: (params) => {
        let columnStatusClassName, columnStatusTextClassName

        if(params.value === 'OFF') {
          columnStatusClassName = `${classes.columnStatus} ${classes.columnStatusOff}`
          columnStatusTextClassName = `${classes.columnStatusText} ${classes.columnStatusTextOff}`
        }
        else if(params.value === 'ON') {
          columnStatusClassName = `${classes.columnStatus} ${classes.columnStatusOn}`
          columnStatusTextClassName = `${classes.columnStatusText} ${classes.columnStatusTextOn}`
        }
        else if(params.value === 'DR') {
          columnStatusClassName = `${classes.columnStatus} ${classes.columnStatusDr}`
          columnStatusTextClassName = `${classes.columnStatusText} ${classes.columnStatusTextDr}`
        }

        return (
          <Box className={columnStatusClassName}>
            <Typography
              variant='subtitle2'
              className={columnStatusTextClassName}
            >
              {params.value}
            </Typography>
          </Box>
        )
      },
    },
    {
      field: 'start',
      headerName: 'Start',
      flex: 1,
      minWidth: 200,
      sortable: false,
    },
    {
      field: 'duration',
      headerName: 'Duration',
      flex: 1,
      minWidth: 200,
      sortable: false,
    },
    {
      field: 'place',
      headerName: 'Place',
      flex: 1,
      minWidth: 200,
      sortable: false,
    },
  ]

  return (
    <CustomDataGridPro
      checkboxSelection={false}
      columns={columns}
      rows={statusTableData}
      hideFooter
      sx={{ height: (statusTableData.length + 1) * 48 }}
    />
  )
}

export default StatusTable