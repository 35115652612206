// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    width: '100%',
    height: '100vh',
    display: 'flex',
  },
  mainContent: {
    flex: 1,
    height: '100%',
    overflow: 'hidden',
  },
}))

export default useStyles