import XLSX from 'xlsx'

// UTILS
import { convertTimeStampToDateOnly } from 'utils/date'

// SUMMARY SECTION

export const exportSummaryListToExcel = (
  inputSummaryListData,
  inputBeginTime,
  inputEndTime,
  inputShift,
) => {
  const sheetSummaryListData = inputSummaryListData.map(item => {
    return {
      'No': item.no,
      'CN': item.deviceName,
      'Cycle': item.totalCycle,
      'Pld (ton)': item.averagePayload,
      'ES (km/h)': item.averageEmptySpeed,
      'LS (km/h)': item.averageLoadedSpeed,
      'Speed (km/h)': item.averageSpeed,
      'LT': item.averageLoadingTime,
      'LDT': item.averageLoadedDriveTime,
      'LST': item.averageLoadedStopTime,
      'EDT': item.averageEmptyDriveTime,
      'EST': item.averageEmptyStopTime,
      'CT': item.averageCycleTime,
      'LDD (km)': item.averageLoadedDriveDistance,
      'EDD (km)': item.averageEmptyDriveDistance,
    }
  })

  const sheetSummaries = XLSX.utils.json_to_sheet(sheetSummaryListData)

  const workBook = XLSX.utils.book_new()

  XLSX.utils.book_append_sheet(workBook, sheetSummaries, 'Summaries')

  XLSX.writeFile(workBook, `PPA Summaries ${convertTimeStampToDateOnly(inputBeginTime)} - ${convertTimeStampToDateOnly(inputEndTime)} ${inputShift}.xlsx`)
}

export const exportSummaryListAndCycleListToExcel = (
  inputSummaryListData, 
  inputCycleListData,
  inputBeginTime,
  inputEndTime,
  inputShift,
) => {
  const sheetSummaryListData = inputSummaryListData.map(item => {
    return {
      'No': item.no,
      'CN': item.deviceName,
      'Cycle': item.totalCycle,
      'Pld (ton)': item.averagePayload,
      'ES (km/h)': item.averageEmptySpeed,
      'LS (km/h)': item.averageLoadedSpeed,
      'Speed (km/h)': item.averageSpeed,
      'LT': item.averageLoadingTime,
      'LDT': item.averageLoadedDriveTime,
      'LST': item.averageLoadedStopTime,
      'EDT': item.averageEmptyDriveTime,
      'EST': item.averageEmptyStopTime,
      'CT': item.averageCycleTime,
      'LDD (km)': item.averageLoadedDriveDistance,
      'EDD (km)': item.averageEmptyDriveDistance,
    }
  })

  const sheetSummaries = XLSX.utils.json_to_sheet(sheetSummaryListData)
  
  const workBook = XLSX.utils.book_new()

  XLSX.utils.book_append_sheet(workBook, sheetSummaries, 'Summaries')
  
  let outputData = []

  inputCycleListData.forEach(item => {
    item.cycles.map(cycleItem => {
      let newItem = {}
      
      newItem['Code Number'] = item.deviceName
      newItem['Date'] = cycleItem.cyclePortTimestamp
      newItem['Pld (ton)'] = cycleItem.payload
      newItem['ES (km/h)'] = cycleItem.emptySpeed
      newItem['LS (km/h)'] = cycleItem.loadedSpeed
      newItem['Speed (km/h)'] = cycleItem.speed
      newItem['LT'] = cycleItem.loadingTime
      newItem['LDT'] = cycleItem.loadedDriveTime
      newItem['LST'] = cycleItem.loadedStopTime
      newItem['EDT'] = cycleItem.emptyDriveTime
      newItem['EST'] = cycleItem.emptyStopTime
      newItem['CT'] = cycleItem.cycleTime
      newItem['LDD (km)'] = cycleItem.loadedDriveDistance
      newItem['EDD (km)'] = cycleItem.emptyDriveDistance
      newItem['Unit Loading'] = cycleItem.unitLoading
      newItem['Port'] = cycleItem.port

      outputData.push(newItem)
      
      return newItem
    })
  })
  
  const sheetCycles = XLSX.utils.json_to_sheet(outputData)
  XLSX.utils.book_append_sheet(workBook, sheetCycles, 'Cycles')

  XLSX.writeFile(workBook, `PPA Summaries And Cycles ${convertTimeStampToDateOnly(inputBeginTime)} - ${convertTimeStampToDateOnly(inputEndTime)} ${inputShift}.xlsx`)
}

// CYCLE SECTION

export const exportCycleListToExcel = (
  inputDeviceName,
  inputCyceListData,
  inputBeginTime,
  inputEndTime,
  inputShift,
) => {    
  const workBook = XLSX.utils.book_new()

  const formatedCycleList = inputCyceListData.map(cycleItem => {
    return {
      'No': cycleItem.no,
      'Date': cycleItem.cyclePortTimestamp,
      'Pld (ton)': cycleItem.payload,
      'ES (km/h)': cycleItem.emptySpeed,
      'LS (km/h)': cycleItem.loadedSpeed,
      'Speed (km/h)': cycleItem.speed,
      'LT': cycleItem.loadingTime,
      'LDT': cycleItem.loadedDriveTime,
      'LST': cycleItem.loadedStopTime,
      'EDT': cycleItem.emptyDriveTime,
      'EST': cycleItem.emptyStopTime,
      'CT': cycleItem.cycleTime,
      'LDD (km)': cycleItem.loadedDriveDistance,
      'EDD (km)': cycleItem.emptyDriveDistance,
      'Unit Loading': cycleItem.unitLoading,
      'Port': cycleItem.port,
    }
  })

  const sheetCycles = XLSX.utils.json_to_sheet(formatedCycleList)
  XLSX.utils.book_append_sheet(workBook, sheetCycles, inputDeviceName)

  XLSX.writeFile(workBook, `PPA Cycles ${inputDeviceName} ${convertTimeStampToDateOnly(inputBeginTime)} - ${convertTimeStampToDateOnly(inputEndTime)} ${inputShift}.xlsx`)
}