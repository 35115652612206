import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

// COMPONENTS
import LoadingBox from 'components/LoadingBox/LoadingBox'
import DataGridFilters from 'components/DataGridFilters/DataGridFilters'
import DialogChangeStatus from './DialogChangeStatus/DialogChangeStatus'
import DialogAddOrEditVehicle from './DialogAddOrEditVehicle/DialogAddOrEditVehicle'
import PageHeader from 'components/PageHeader/PageHeader'

// CUSTOM COMPONENTS
import CustomDataGridPro from 'components/Customs/CustomDataGridPro'
import CustomSwitch from 'components/Customs/CustomSwitch'

// DATA
import vehiclesTableData from './vehiclesTableData'

// MUIS
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

// STYELS
import pageStyles from 'styles/layoutMainPageUseStyles'
import useStyles from './vehiclesUseStyles'

const Vehicles = () => {
  const pageClasses = pageStyles()
  const classes = useStyles()

  const pageRef = useRef()

  const navigate = useNavigate()

  const initialColumns = [
    {
      field: 'vehicle',
      headerName: 'Image',
      width: 50,
      renderHeader: () => (<></>),
      sortable: false,
      renderCell: (params) => (
        <Box 
          component='img'
          src={'https://image.shutterstock.com/image-photo/large-quarry-dump-truck-loading-260nw-1317200894.jpg'}
          alt=''
          className={classes.columnVehicleImage}
        />
      ),
    },
    {
      field: 'name',
      headerName: 'Vehicle',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <Box className={classes.columnVehicle}>
          {/* TEXT */}
          <Typography
            variant='subtitle2'
            className={classes.columnVehicleTextPrimary}
          >
            {params.value}
          </Typography>

          {/* TEXT */}
          <Typography
            variant='subtitle2'
            className={classes.columnVehicleTextSecondary}
          >
            {params.row.licenseNumber}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'site',
      headerName: 'Site',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'mileage',
      headerName: 'Mileage',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'hourMeter',
      headerName: 'Hour Meter',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'lastUpdate',
      headerName: 'Last Update',
      flex: 1,
      minWidth: 200,
    },
    {
      field: 'place',
      headerName: 'Place',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'center',
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        <Box className={classes.columnStatus}>
          <CustomSwitch 
            className={classes.switch}
            checked={params.value}
            onClick={(event) => handleStatusSwitchClick(event, params)}
          />
        </Box>
      )
    },
  ]

  const [ search, setSearch ] = useState('')
  const [ tableData, setTableData ] = useState([])
  const [ dialogAddOrEditVehicle, setDialogAddOrEditVehicle ] = useState(null)
  const [ isLoading, setIsLoading ] = useState(false)
  const [ selectedColumnList, setSelectedColumnList ] = useState(initialColumns)
  const [ dialogChangeStatus, setDialogChangeStatus ] = useState(null)

  const handleDownloadIconButtonClick = () => {}

  const handleStatusSwitchClick = (inputEvent, inputParams) => {
    inputEvent.stopPropagation()
    setDialogChangeStatus(inputParams)
  }

  useEffect(() => {
    setTableData(vehiclesTableData)
  }, [])

  return (
    <Box 
      className={pageClasses.pageRoot}
      ref={pageRef}
    >
      {/* PAGE HEADER */}
      <PageHeader
        isAddButtonAvailable={true}
        onAddButtonIsClicked={() => setDialogAddOrEditVehicle(true)}
        isBackButtonAvailable={false}
        targetBackUrl={null}
        title='Vehicles'
        isSearchAvailable={true}
        search={search}
        setSearch={setSearch}
        isExtraComponentAvailable={false}
        extraComponent={null}
      />

      {/* CONTENT */}
      <LoadingBox isLoading={isLoading}>
        {/* DATA GRID FILTERS */}
        <DataGridFilters
          isWithTabs={false}
          title={'Vehicle List'}
          selectedTab={null}
          setSelectedTab={null}
          tabItemList={null}
          handleDownloadIconButtonClick={handleDownloadIconButtonClick}
          isWithCustomizeColumn={true}
          columns={initialColumns}
          selectedColumnList={selectedColumnList}
          setSelectedColumnList={setSelectedColumnList}
          isWithDateRange={false}
          dateRangeValue={null}
          setDateRangeValue={null}
        />

        {/* TABLE */}
        <CustomDataGridPro
          columns={selectedColumnList}
          rows={tableData}
          onRowClick={(params, event, details) => navigate(`/vehicles/${params.id}`)}
        />
      </LoadingBox>

      {/* DIALOG ADD OR EDIT VEHICLE */}
      <DialogAddOrEditVehicle
        dialogType='add'
        dialogAddOrEditVehicle={dialogAddOrEditVehicle}
        setDialogAddOrEditVehicle={setDialogAddOrEditVehicle}
        pageRef={pageRef}
      />

      {/* DIALOG CHANGE STATUS */}
      {dialogChangeStatus &&
      <DialogChangeStatus
        dialogChangeStatus={dialogChangeStatus}
        setDialogChangeStatus={setDialogChangeStatus}
      />}
    </Box>
  )
}

export default Vehicles