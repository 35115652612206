// CONSTANTS
import { colors } from 'constants/colors'

// MUI STYLES
import { alpha } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  columnStatus: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 40,
    padding: '4px 16px',
  },
  columnStatusOff: {
    backgroundColor: alpha(colors.icon, 0.1),
  },
  columnStatusOn: {
    backgroundColor: alpha(colors.green1, 0.1),
  },
  columnStatusDr: {
    backgroundColor: alpha(colors.orange1, 0.1),
  },
  columnStatusText: {
    fontSize: 'inherit',
    fontWeight: 600,
  },
  columnStatusTextOff: {
    color: colors.icon,
  },
  columnStatusTextOn: {
    color: colors.green1,
  },
  columnStatusTextDr: {
    color: colors.orange1,
  },
}))

export default useStyles