import moment from 'moment'

// UTILS
import simpleCrypto from 'utils/simpleCrypto'

const keyUserProfile = 'ppa-user-profile'
const keyStartDate = 'ppa-start-date'
const keyEndDate = 'ppa-end-date'
const keyAccessToken = 'ppa-user-access-token'
const keyRefreshToken = 'ppa-user-refresh-token'

const defaultStartDate = moment().subtract(72, 'hour').toDate() // LAST 3 DAYS
const defaultEndDate = moment().toDate()

// USER PROFILE

export const saveUserProfileToLocalStorage = (inputUserProfile) => {
  localStorage.setItem(keyUserProfile, simpleCrypto.encrypt(inputUserProfile))
}

export const readUserProfileFromLocalStorage = () => { 
  return localStorage.getItem(keyUserProfile) ? 
  simpleCrypto.decrypt(localStorage.getItem(keyUserProfile)) : 
  null
}

export const removeUserProfileFromLocalStorage = () => {
  localStorage.removeItem(keyUserProfile)
}

// USER ACCESS TOKEN
export const saveUserAccessTokenToLocalStorage = (inputAccessToken) => {
  localStorage.setItem(keyAccessToken, simpleCrypto.encrypt(inputAccessToken))
}

export const getUserAccessTokenFromLocalStorage = () => {
  return simpleCrypto.decrypt(localStorage.getItem(keyAccessToken))
}

export const removeUserAccessTokenFromLocalStorage = () => {
  localStorage.removeItem(keyAccessToken)
}

// USER REFRESH TOKEN
export const saveUserRefreshTokenToLocalStorage = (inputRefreshToken) => {
  localStorage.setItem(keyRefreshToken, simpleCrypto.encrypt(inputRefreshToken))
}

export const getUserRefreshTokenFromLocalStorage = () => {
  return simpleCrypto.decrypt(localStorage.getItem(keyRefreshToken))
}

export const removeUserRefreshTokenFromLocalStorage = () => {
  localStorage.removeItem(keyRefreshToken)
}

// START DATE

export const setStartDateToLocalStorage = (inputStartDate) => {
  localStorage.setItem(keyStartDate, inputStartDate)
}

export const getStartDateFromLocalStorage = () => {
  if(localStorage.getItem(keyStartDate) != null){
    return new Date(localStorage.getItem(keyStartDate))
  }
  else return defaultStartDate
}

export const removeStartDateFromLocalStorage = () => {
  localStorage.removeItem(keyStartDate)
}

// END DATE

export const setEndDateToLocalStorage = (inputEndDate) => {
  localStorage.setItem(keyEndDate, inputEndDate)
}

export const getEndDateFromLocalStorage = () => {
  if(localStorage.getItem(keyEndDate) != null){
    return new Date(localStorage.getItem(keyEndDate))
  }
  else return defaultEndDate
}

export const removeEndDateFromLocalStorage = () => {
  localStorage.removeItem(keyEndDate)
}

// COLLECTION

export const removeAllDataFromLocalStorage = () => {
  removeUserProfileFromLocalStorage()
  removeUserAccessTokenFromLocalStorage()
  removeUserRefreshTokenFromLocalStorage()
  removeStartDateFromLocalStorage()
  removeEndDateFromLocalStorage()
}