import React, { useContext } from 'react'

// COMPONENTS
import Drawer from 'components/Drawer/Drawer'
import Snackbar from 'components/Snakbar/Snackbar'

// CONTEXTS
import { MainLayoutContext } from 'contexts/MainLayoutContext'

// MUIS
import Box from '@mui/material/Box'

// STYLES
import useStyles from './mainUseStyles'

const Main = (props) => {
  const { children } = props

  const classes = useStyles()

  const { 
    snackbarObject, 
    changeSnackbarObject, 
  } = useContext(MainLayoutContext)

  return (
    <Box className={`${classes.mainRoot} no-zoom`}>
      <Drawer/>

      {/* CONTENT */}
      <Box className={`${classes.mainContent} zoom`}>
        {children}
      </Box>

      <Snackbar
        open={snackbarObject.open}
        setToast={changeSnackbarObject}
        severity={snackbarObject.severity}
        message={snackbarObject.message}
      />
    </Box>
  )
}

export default Main