// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  chartRoot: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  title: {
    margin: '24px 24px 0px',
  },
  averageUsage: {
    margin: '0px 24px',
    fontSize: 28,
    fontWeight: 600,
  },
  chartContainer: {
    flex: '1 1 0',
    width: '100%',
    overflow: 'auto',
  },
  chart: {
    width: '100%',
    height: '100%',
  },
}))

export default useStyles