import { useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'

// COMPONENTS
import AuthenticationRoute from 'components/Routes/AuthenticationRoute'
import PrivateRoute from 'components/Routes/PrivateRoute'

// CONSTANTS
import { values } from 'constants/values'

// ROUTES
import routes from 'routes/routes'

// UTILITIES
import { getCompanyLogo } from 'utils/assets'

const App = () => {
  // UPDATE FAVICON AND APP TITLE
  useEffect(() => {
    const faviconElement = document.getElementById('favicon')
    faviconElement.href = getCompanyLogo('logo')

    if (values.dashboardType === values.dashboardTypePpa) document.title = 'PPA'
    else if (values.dashboardType === values.dashboardTypeIot) document.title = 'IOT'
  }, [])

  return (
    <Routes>
      {routes.map((item, index) => (
        <Route 
          key={index}
          path={item.path} 
          element={
            item.routeType === 'authentication' ?
            <AuthenticationRoute>
              {item.element}
            </AuthenticationRoute> :
            <PrivateRoute>
              {item.element}
            </PrivateRoute>
          }
        />
      ))}
    </Routes>
  )
}

export default App