// CONSTANTS
import { colors } from 'constants/colors'

// MUIS
import { alpha } from '@mui/material/styles'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  panelContainer: {
    position: 'absolute',
    zIndex: 2,
    backgroundColor: 'white',
    borderRadius: 2,
    right: 20,
    top: 20,
    boxShadow: theme.shadows[4],
    '& .MuiButtonGroup-grouped:not(:last-of-type)': {
      borderColor: theme.palette.divider,
    },
  },
  buttonGroupItem: {
    width: 40,
    height: 40,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  icon: {
    color: colors.iconDark,
  },
  iconActive: {
    color: theme.palette.primary.main,
  },
  mapMenu: {
    transform: 'translate(0px, 20px)',
    '& .MuiList-root': {
      width: 372,
      padding: 0,
    },
  },
  mapMenuHeader: {
    display: 'flex',
    alignItems: 'center',
    height: 62,
    paddingLeft: 18,
    paddingRight: 12,
  },
  mapMenuHeaderTitle: {
    marginLeft: 12,
    marginRight: 'auto',
  },
  mapOptionList: {
    '&.MuiList-root': {
      padding: '8px 4px',
    },
  },
  mapOptionItem: {
    padding: 0,
    height: 45,
  },
  mapOptionItemButton: {
    padding: '0px 12px 0px 4px',
    borderRadius: 2,
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.04),
    },
  },
}))

export default useStyles