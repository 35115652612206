// PAGES
import Canbus from 'pages/Canbus/Canbus'
import Dashboard from 'pages/Dashboard/Dashboard'
import FuelReport from 'pages/FuelReport/FuelReport'
import Payload from 'pages/Payload/Payload'
import Routes from 'pages/Routes/Routes'
import RoutesDetail from 'pages/RoutesDetail/RoutesDetail'
import Settings from 'pages/Settings/Settings'
import SignIn from 'pages/SignIn/SignIn'
import Tracking from 'pages/Tracking/Tracking'
import VehiclesDetail from 'pages/VehiclesDetail/VehiclesDetail'
import Vehicles from 'pages/Vehicles/Vehicles'

const routes = [
  {
    path: '/sign-in',
    element: <SignIn/>,
    routeType: 'authentication',
  },
  {
    path: '/',
    element: <Dashboard/>,
    routeType: 'private',
  },
  {
    path: '/fuel-report',
    element: <FuelReport/>,
    routeType: 'private',
  },
  {
    path: '/payload',
    element: <Payload/>,
    routeType: 'private',
  },
  {
    path: '/canbus',
    element: <Canbus/>,
    routeType: 'private',
  },
  {
    path: '/routes',
    element: <Routes/>,
    routeType: 'private',
  },
  {
    path: '/routes/deviceId=:deviceId/shift=:shift',
    element: <RoutesDetail/>,
    routeType: 'private',
  },
  {
    path: '/settings',
    element: <Settings/>,
    routeType: 'private',
  },
  {
    path: '/tracking',
    element: <Tracking/>,
    routeType: 'private',
  },
  {
    path: '/vehicles/:vehicleId',
    element: <VehiclesDetail/>,
    routeType: 'private',
  },
  {
    path: '/vehicles',
    element: <Vehicles/>,
    routeType: 'private',
  },
]

export default routes