// ASSETS
import BackgroundAuthentication from 'assets/images/backgrounds/authentication.png'

// CONSTANTS
import { colors } from 'constants/colors'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  signInRoot: {
    backgroundImage: `url(${BackgroundAuthentication})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    height: '100%',
  },
  imageContainer: {
    flex: 1,
    height: '100%',
    backgroundColor: 'transparent',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    height: '45vh',
    borderRadius: 16,
  },
  form: { 
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    width: 435,
    padding: '50px 80px',
    position: 'relative',
  },
  logoCompany: {
    width: 185,
    position: 'absolute',
    top: 50,
  },
  formItem: {
    display: 'flex',
    alignItems: 'center',
    height: 64,
    width: '100%',
  },
  formItemIcon: {
    marginRight: 9,
    color: colors.icon,
  },
  input: {
    width: '100%',
  },
  inputHelper: {
    height: 15,
  },
  iconPassword: {
    color: colors.iconDark,
    cursor: 'pointer',
  },
  button: {
    margin: '24px 0px',
    width: '100%',
  },
}))

export default useStyles