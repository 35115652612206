import React from 'react'
import { Navigate } from 'react-router-dom'

// LAYOUT
import Authentication from 'layouts/Authentication/Authentication'

// UTILS
import { readUserProfileFromLocalStorage } from 'utils/localStorage'

const AuthenticationRoute = (props) => {
  const { children } = props

  const userProfile = readUserProfileFromLocalStorage()

  return (
    userProfile ? 
    <Navigate 
      replace 
      to='/'
    /> : 
    <Authentication> 
      {children}
    </Authentication>
  )
}

export default AuthenticationRoute
