import React, { useState, useEffect } from 'react'

// COMPONENTS
import DateRangeAndTimePicker from 'components/DateRangeAndTimePicker/DateRangeAndTimePicker'

// CUSTOM COMPONENTS
import CustomInput from 'components/Customs/CustomInput'

// MUIS
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import FormControl from '@mui/material/FormControl'
import InputAdornment from '@mui/material/InputAdornment'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconEventOutlined from '@mui/icons-material/EventOutlined'

// STYLES
import useStyles from './headerUseStyles'

// UTILS
import { convertTimeStampToDateOnly } from 'utils/date'
import {
  setStartDateToLocalStorage,
  setEndDateToLocalStorage,
} from 'utils/localStorage'

const Header = (props) => {
  const { 
    dateRangeValue,
    setDateRangeValue,
  } = props

  const classes = useStyles()

  const [ isDateRangePickerOpen, setIsDateRangePickerOpen ] = useState(false)

  useEffect(() => {
    if(dateRangeValue[0] && dateRangeValue[1]) {
      setStartDateToLocalStorage(dateRangeValue[0])
      setEndDateToLocalStorage(dateRangeValue[1])
    }
  }, [dateRangeValue])

  return (
    <Box className={classes.root}>
      {/* TITLE */}
      <Typography 
        variant='h6'
        className={classes.title}
      >
        Dashboard
      </Typography>

      {/* DATE RANGE AND TIME PICKER INPUT */}
      <FormControl 
        variant='standard' 
        className={classes.dateRangePicker}
      >
        <CustomInput
          value={`${convertTimeStampToDateOnly(dateRangeValue[0])} - ${convertTimeStampToDateOnly(dateRangeValue[1])}`}
          onClick={(e) => setIsDateRangePickerOpen(true)}
          startAdornment={
            <InputAdornment position='start'>
              <IconEventOutlined 
                className={classes.dateRangePickerIcon}
                onClick={() => setIsDateRangePickerOpen(true)}
              />
            </InputAdornment>
          }
        />
      </FormControl>

      {/* DATE RANGE AND TIME PICKER */}
      <Dialog 
        open={isDateRangePickerOpen}
        onClose={() => setIsDateRangePickerOpen(false)} 
        className={classes.dateRangePickerDialog}
      >
        <DateRangeAndTimePicker
          value={dateRangeValue}
          setValue={setDateRangeValue}
          setOpen={setIsDateRangePickerOpen}
          isWithTimePicker={false}
        />
      </Dialog>
    </Box>
  )
}

export default Header