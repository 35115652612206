import React from 'react'
import { Navigate } from 'react-router-dom'

// CONTEXTS
import { MainLayoutContextProvider } from 'contexts/MainLayoutContext'

// LAYOUT
import Main from 'layouts/Main/Main'

// UTILS
import { readUserProfileFromLocalStorage } from 'utils/localStorage'

const PrivateRoute = (props) => {
  const { children } = props

  const userProfile = readUserProfileFromLocalStorage()

  return (
    userProfile ?
    <MainLayoutContextProvider>
      <Main> 
        {children}
      </Main> 
    </MainLayoutContextProvider> :
    <Navigate 
      replace 
      to='/sign-in'
    />
  )
}

export default PrivateRoute
