// MUIS
import { grey } from '@mui/material/colors'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  markerContainer: {
    position: 'relative',
  },
  markerMoving: {
    color: theme.palette.primary.main,
  },
  markerParking: {
    border: '2px solid white',
    '&.MuiAvatar-root': {
      width: 24,
      height: 24,
      fontSize: 14,
      backgroundColor: theme.palette.primary.main,
    },
  },
  markerLabel: {
    position: 'absolute',
    display: 'block',
    left: 24,
    top: 24,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    padding: '2px 4px',
    border: `1px solid ${grey[100]}`,
    minWidth: 'fit-content',
  },
}))

export default useStyles