import React from 'react'
import PropTypes from 'prop-types'

// MUIS
import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import Slide from '@mui/material/Slide'

// STYLES
import useStyles from './dialogAddOrEditUseStyles'

const DialogAddOrEdit = (props) => {
  const {
    open,
    containerRef,
    children,
  } = props

  const classes = useStyles()

  return (
    <Backdrop
      open={open}
      className={classes.root}
    >
      <Slide 
        direction='right' 
        in={open} 
        container={containerRef.current}
      >
        <Box className={classes.contentContainer}>
          {children}
        </Box>
      </Slide>
    </Backdrop>
  )
}

DialogAddOrEdit.defaultProps = {
  open: false,
}

DialogAddOrEdit.propTypes = {
  open: PropTypes.bool.isRequired,
  containerRef: PropTypes.oneOfType([
    PropTypes.func, 
    PropTypes.shape({ current: PropTypes.any }),
  ]).isRequired,
  children: PropTypes.node.isRequired,
}

export default DialogAddOrEdit