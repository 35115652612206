import React, { useEffect } from 'react'

// LEAFLET
import { 
  useMap, 
  useMapEvents,
} from 'react-leaflet'

const MapChildren = (props) => {
  const { 
    zoom, 
    setZoom,
    deviceList,
    setBoundsBox,
  } = props

  const map = useMap()

  const mapEvents = useMapEvents({
    zoomend(event) {
      setZoom(event.sourceTarget._zoom)
    },
  })

  // UDPATE MAP ZOOM
  useEffect(() => {
    map.setZoom(zoom)

    const newBoundsBox = map.getBounds()
    setBoundsBox([
      newBoundsBox.getSouthWest().lng,
      newBoundsBox.getSouthWest().lat,
      newBoundsBox.getNorthEast().lng,
      newBoundsBox.getNorthEast().lat
    ])
  }, [zoom])

  // UPDATE MAP BOUNDS
  // useEffect(() => {
  //   const selectedDeviceList = deviceList.filter(item => item.isSelected === true)
  //   const bounds = selectedDeviceList.map(item => [ item.source.lastY, item.source.lastX ])

  //   if(bounds.length > 0) map.fitBounds(bounds)
  // }, [deviceList])

  return (<></>)
}

export default MapChildren