// APIS
import axios from 'apis/axios'

// UTILS
import { getUserRefreshTokenFromLocalStorage } from 'utils/localStorage'

export const postRefreshToken = async () => {
  try {
    const response = await axios.post(
      '/auth/refresh-token',
      JSON.stringify({
        refreshToken: getUserRefreshTokenFromLocalStorage(),
      }),
      { 
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )

    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}