import moment from 'moment'

export const getAllDatesFrom2Dates = (inputStartDate, inputEndDate) => {
  let output = []
  let currentDate = inputStartDate
  while (currentDate <= inputEndDate) {
    output.push(moment(currentDate).format('MMM-DD'))
    currentDate = moment(currentDate).add(1, 'days')
  }

  return output
}