// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconTracking = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M18.7872 5.21279L17.4336 6.56639C18.816 7.95839 19.68 9.87839 19.68 12C19.68 16.2432 16.2432 19.68 12 19.68C7.75679 19.68 4.31999 16.2432 4.31999 12C4.31999 8.08319 7.24799 4.85759 11.04 4.38719V6.32639C8.31359 6.78719 6.23999 9.14879 6.23999 12C6.23999 15.1776 8.82239 17.76 12 17.76C15.1776 17.76 17.76 15.1776 17.76 12C17.76 10.4064 17.1168 8.96639 16.0704 7.92959L14.7168 9.28319C15.408 9.98399 15.84 10.944 15.84 12C15.84 14.1216 14.1216 15.84 12 15.84C9.87839 15.84 8.15999 14.1216 8.15999 12C8.15999 10.2144 9.38879 8.72639 11.04 8.29439V10.3488C10.464 10.6848 10.08 11.2896 10.08 12C10.08 13.056 10.944 13.92 12 13.92C13.056 13.92 13.92 13.056 13.92 12C13.92 11.2896 13.536 10.6752 12.96 10.3488V2.39999H12C6.70079 2.39999 2.39999 6.70079 2.39999 12C2.39999 17.2992 6.70079 21.6 12 21.6C17.2992 21.6 21.6 17.2992 21.6 12C21.6 9.35039 20.5248 6.95039 18.7872 5.21279Z"/>
    </SvgIcon>
  )
}

export default IconTracking