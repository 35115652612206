import React, { createContext } from 'react'

const AllPagesContext = createContext()

const AllPagesContextProvider = (props) => {
  return (
    <AllPagesContext.Provider
      value={{}}
    >
      {props.children}
    </AllPagesContext.Provider>
  )
}

export { AllPagesContextProvider, AllPagesContext }