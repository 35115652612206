// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  authenticationRoot: {
    width: '100%',
    height: '100vh',
    display: 'flex',
  },
  authenticationContent: {
    flex: 1,
    height: '100%',
  },
}))

export default useStyles