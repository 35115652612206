// CONSTANTS
import { colors } from 'constants/colors'
import { values } from 'constants/values'

// MUIS
import { alpha } from '@mui/material/styles'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  drawerRoot: {
    display: 'flex',
    flexDirection: 'column',
    '& .MuiPaper-root': {
      zIndex: 1200 + 1
    },
  },
  drawerHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 72,
    position: 'relative',
    padding: '8px 12px',
  },
  iconToggle: {
    position: 'absolute',
    left: 16,
    transform: 'rotateY(0deg)',
    transition: 'transform 0.25s ease-in-out',
  },
  iconToogleOnClose: {
    position: 'absolute',
    left: 16,
    transform: 'rotateY(180deg)',
    transition: 'transform 0.25s ease-in-out',
  },
  logoCompany: {
    height: 28,
  },
  siteSelect: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 16px',
    height: 64,
  },
  siteAvatar: {
    backgroundColor: colors.grey,
    marginRight: 16,
  },
  siteIcon: {
    color: colors.iconDark,
  },
  siteTitle: {
    color: theme.palette.text.secondary,
    fontWeight: 400,
    fontSize: 12,
  },
  siteValue: {
    color: theme.palette.text.primary,
    fontWeight: 400,
  },
  siteIconDropDown: {
    color: colors.icon,
    marginLeft: 'auto',
    marginRight: 8,
  },
  siteMenu: {
    transform: 'translateX(-16px)',
    '& .MuiPaper-root': {
      width: values.drawerWidth,
    },
  },
  navigationItem: {
    height: 48,
    paddingLeft: 24,
  },
  navigationItemActive: {
    color: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
  },
  navigationIcon: {
    marginRight: 24,
    minWidth: 'unset',
  },
  navigationIconCustom: {
    width: 26,
    height: 26,
  },
  navigationText: {
    '& .MuiTypography-root': {
      fontSize: 14,
    },
  },
  navigationBottom: {
    marginTop: 'auto',
  },
}))

export default useStyles