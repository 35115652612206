import { format } from 'date-fns'

export const convertTimeStampToDateOnly = (inputTimeStamp) => {
  const date = (new Date(inputTimeStamp)).toLocaleString('en-US', { })
  const formattedDate = format(new Date(date), 'yyyy-MM-dd')
  return formattedDate
}

export const convertTimeStampToTimeStampAt12PM = (inputTimeStamp) => {
  const date = new Date(inputTimeStamp)
  date.setHours(12, 0, 0)
  return date.getTime()
}

export const convertTimeStampToTimeStampAt12AM = (inputTimeStamp) => {
  const date = new Date(inputTimeStamp)
  date.setHours(24, 0, 0)
  return date.getTime()
}

export const convertSecondToHMS = (inputSecond) => {
  const hour = Math.floor(inputSecond / 3600)
  const minute = Math.floor(inputSecond % 3600 / 60)
  const second = Math.floor(inputSecond % 3600 % 60)

  const hourDisplay = hour >= 10 ? hour : `0${hour}`
  const minuteDisplay = minute >= 10 ? minute : `0${minute}`
  const secondDisplay = second >= 10 ? second : `0${second}`

  if(inputSecond === 0){
    return '00:00:00'
  }
  else{
    return `${hourDisplay}:${minuteDisplay}:${secondDisplay}`
  }
}

export const convertValueTo1DecimalPoint = (inputValue) => {
  const rounded = Math.round(inputValue * 10) / 10
  return rounded
}

export const convertTimeStampToDateAndTimeGMT7 = (inputTimeStamp) => {
  const date = (new Date(inputTimeStamp)).toLocaleString('en-US', {timeZone: 'Asia/Jakarta'})
  const formattedDate = format(new Date(date), 'yyyy-MM-dd HH:mm:ss')
  return formattedDate
}

export const convertTimeStampToDateAndTimeGMT8 = (inputTimeStamp) => {
  const date = (new Date(inputTimeStamp)).toLocaleString('en-US', {timeZone: 'Asia/Brunei'})
  const formattedDate = format(new Date(date), 'yyyy-MM-dd HH:mm:ss')
  return formattedDate
}

export const convertTimeStampToHourMinute = (inputTimeStamp) => {
  const date = (new Date(inputTimeStamp)).toLocaleString('en-US', {timeZone: 'Asia/Jakarta'})
  const formattedDate = format(new Date(date), 'HH:mm')
  return formattedDate
} 

export const convertTimeStampToHMS = (inputTimeStamp) => {
  const date = (new Date(inputTimeStamp)).toLocaleString('en-US', {timeZone: 'Asia/Jakarta'})
  const formattedDate = format(new Date(date), 'HH:mm:ss')
  return formattedDate
} 

export const convertDateToIsoStringDate = (inputDate) => {
  return `${inputDate.getFullYear()}-${inputDate.getMonth() + 1}-${inputDate.getDate()}T${inputDate.getHours()}:${inputDate.getMinutes()}:${inputDate.getSeconds()}`
}