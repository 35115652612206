// MUIS
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { styled } from '@mui/material/styles'

const CustomToggleButtonGroup = styled(({ className, ...props }) => (
  <ToggleButtonGroup
    className={className} 
    {...props}
  />
))(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    border: 0,
    '&.Mui-selected': {
      backgroundColor: 'transparent',
    },
  },
  '& .MuiToggleButton-root': {
    padding: '2px 4px',
    backgroundColor: 'transparent',
  },
}))

export default CustomToggleButtonGroup