import React, { useEffect, useRef } from 'react'
import moment from 'moment'

// CONSTANTS
import { colors } from 'constants/colors'

// ECHARTS
import { init, getInstanceByDom } from 'echarts'

// MUIS
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'

// STYLES
import useStyles from './chartLogEventsUseStyles'

// UTILS
import { 
  convertTimeStampToHourMinute,
  convertTimeStampToHMS, 
} from 'utils/date'

const ChartLogEvents = () => {
  const classes = useStyles()

  const chartRef = useRef()

  const startTime = +moment().startOf('day').toDate()
  const endTime = +moment().add(1, 'days').startOf('days').toDate()

  const chartOption = {
    tooltip: {
      // trigger: 'axis',
      formatter: (params) => {
        return params.data[1] + ': ' + convertTimeStampToHMS(params.data[0])
      }
    },
    grid: {
      top: 20,
      left: 20,
      right: 40,
      bottom: 20,
      containLabel: true,
    },
    xAxis: {
      min: startTime,
      max: endTime,
      scale: true,
      position: 'top',
      maxInterval: 3600000,
      axisLabel: {
        formatter: (val) => {
          return convertTimeStampToHourMinute(val)
        },
      },
    },
    yAxis: {
      type: 'category',
      data: [ 'OFF', 'D', 'ON' ],
    },
    visualMap: {
      show: false,
      dimension: 0,
      pieces: [
        {
          lte: 2,
          color: colors.blue1,
        },
        {
          gt: 2,
          lte: 3,
          color: colors.red2,
        },
        {
          gt: 3,
          lte: 14,
          color: colors.blue1,
        },
        {
          gt: 14,
          lte: 17,
          color: colors.red2,
        },
        {
          gt: 17,
          color: colors.blue1,
        },
      ],
    },
    series: [
      {
        name: 'Log Events',
        type: 'line',
        step: 'start',
        data: [
          [ 1646758800000, 'ON' ],
          [ 1646784000000, 'D' ],
          [ 1646787600000, 'OFF' ],
          [ 1646794800000, 'ON' ],
          [ 1646802000000, 'OFF' ],
          [ 1646812800000, 'D' ],
          [ 1646841600000, 'ON' ],
          [ 1646845200000, 'D' ],
        ],
      },
    ],
  }

  useEffect(() => {
    let chart
    if (chartRef.current !== null) chart = init(chartRef.current)

    const resizeChart = () => {
      chart?.resize()
    }
    window.addEventListener('resize', resizeChart)

    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current)
      chart.setOption(chartOption)
    }

    return () => {
      chart?.dispose()
      window.removeEventListener('resize', resizeChart)
    }
  }, [])

  return (
    <Box className={classes.chartContainer}>
      {/* TITLE */}
      <Typography
        variant='subtitle1'
        className={classes.title}
      >
        Log Events
      </Typography>

      <Divider/>

      {/* CHART */}
      <Box
        ref={chartRef}
        className={classes.chart}
      />

      {/* VIOLATION */}
      <Box className={classes.violationContainer}>
        <Typography
          variant='caption'
          className={classes.violationText}
        >
          Violation:&nbsp;
          <span className={classes.violationTextRed}>
            30 Minutes Break Required
          </span>
        </Typography>
      </Box>
    </Box>
  )
}

export default ChartLogEvents