// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 24,
  },
  cardItem: {
    padding: 16,
  },
  cardItemTitle: {
    fontWeight: 600,
    marginBottom: 16,
  },
}))

export default useStyles