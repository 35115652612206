import { exportComponentAsPNG } from 'react-component-export-image'
import XLSX from 'xlsx'

// CONSTANTS
import { colors } from 'constants/colors'

// ECHARTS
import * as echarts from 'echarts'

// UTILS
import { convertTimeStampToDateOnly } from 'utils/date'

export const exportFuelReportListToExcel = (
  inputFuelReportListData,
  inputBeginTime,
  inputEndTime,
  inputShift,
) => {
  const sheetFuelReportData = inputFuelReportListData.map((item, index) => {
    return {
      'No': index + 1,
      'CN': item.deviceName,
      'Fuel Consumption Rate L/h': item.fuelReportPerHour,
      'Status': item.status,
    }
  })

  const sheetFuelReport = XLSX.utils.json_to_sheet(sheetFuelReportData)
  
  const workBook = XLSX.utils.book_new()

  XLSX.utils.book_append_sheet(workBook, sheetFuelReport, 'Fuel Report')

  XLSX.writeFile(workBook, `PPA Fuel Report ${convertTimeStampToDateOnly(inputBeginTime)} - ${convertTimeStampToDateOnly(inputEndTime)} ${inputShift}.xlsx`)
}

export const getChartOption = (
  inputXAxisData,
  inputYAxisData,
) => {
  return  {
    color: [ colors.blue1, ],
    grid: {
      left: 48,
      right: 48,
      bottom: 48,
      containLabel: true,
    },
    series: [
      {
        name: 'Fuel Usage',
        type: 'line',
        stack: 'Total',
        smooth: true,
        lineStyle: {
          width: 0
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: colors.blue1,
            },
            {
              offset: 1,
              color: 'white',
            },
          ]),
        },
        emphasis: {
          focus: 'series',
        },
        data: inputYAxisData,
      },
    ],
    textStyle: {
      fontFamily: [ 'Open Sans', 'sans-serif' ],
      fontSize: 12,
      fontStyle: 'normal',
      fontWeight: 400,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985',
        },
      },
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        data: inputXAxisData,
        name: 'Device Name',
        nameLocation: 'center',
        nameTextStyle: {
          align: 'left',
          fontSize: 14,
        },
        nameGap: 30,
      },
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Fuel Consumption Rate L/h',
        nameLocation: 'center',
        nameTextStyle: {
          align: 'left',
          fontSize: 14,
        },
        nameGap: 30,
      },
    ],
  }
}

export const exportChartToImage = (
  inputComponentRef,
  inputBeginTime,
  inputEndTime,
  inputShift,
) => {
  exportComponentAsPNG(
    inputComponentRef,
    { fileName: `PPA Fuel Report ${convertTimeStampToDateOnly(inputBeginTime)} - ${convertTimeStampToDateOnly(inputEndTime)} ${inputShift}` },
  )
}