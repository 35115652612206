import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from './App'

// CONSTANTS
import customTheme from 'constants/customTheme'

// CONTEXTS
import { AllPagesContextProvider } from 'contexts/AllPagesContext'

// MUI DATA GRIDS
import { LicenseInfo } from '@mui/x-data-grid-pro'

// MUIS
import { 
  StyledEngineProvider, 
  ThemeProvider, 
} from '@mui/material/styles'

// REACT GEOCODE
import Geocode from 'react-geocode'

// STYLES
import './index.css'

LicenseInfo.setLicenseKey(process.env.REACT_APP_DATA_GRID_PRO_LICENSE)

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY)
Geocode.setLocationType('ROOFTOP')

ReactDOM.render(
  <ThemeProvider theme={customTheme}>
    <StyledEngineProvider injectFirst>
      <AllPagesContextProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AllPagesContextProvider>
    </StyledEngineProvider>
  </ThemeProvider>,
  document.getElementById('root')
)
