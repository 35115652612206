// CONSTANTS
import { colors } from 'constants/colors'

// MUIS
import { grey } from '@mui/material/colors'
import { alpha } from '@mui/material/styles'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  chartContainer: {
    width: '100%',
    height: 'fit-content',
    flexShrink: 0,
  },
  title: {
    fontWeight: 600,
    margin: 16,
  },
  chart: {
    width: '100%',
    height: 250,
  },
  violationContainer: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    height: 32,
    margin: '0px 20px 20px',
    padding: '0px 12px',
  },
  violationText: {
    color: grey[600],
  },
  violationTextRed: {
    color: theme.palette.primary.main,
  },
}))

export default useStyles