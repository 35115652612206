import React from 'react'

// CUSTOM COMPONENTS
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'

// MUIS
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconArrowLeft from '@mui/icons-material/ArrowLeft'
import IconArrowRight from '@mui/icons-material/ArrowRight'
import IconFileDownload from '@mui/icons-material/FileDownload'

// STYLES
import useStyles from './contentHeaderUseStyles'

const ContentHeader = () => {
  const classes = useStyles()

  return (
    <Box className={classes.contentHeaderRoot}>
      {/* TITLE */}
      <Typography
        variant='subtitle1'
        className={classes.title}
      >
        Trips
      </Typography>

      {/* DOWNLOAD ICON */}
      <CustomTooltipBlack
        title='Download'
        placement='top'
      >
        <IconButton className={classes.iconDownload}>
          <IconFileDownload className={classes.iconAction}/>
        </IconButton>
      </CustomTooltipBlack>

      {/* PREVIOUS DAY ICON */}
      <CustomTooltipBlack
        title='Previous Day'
        placement='top'
      >
        <IconButton className={classes.iconButton}>
          <IconArrowLeft className={classes.iconAction}/>
        </IconButton>
      </CustomTooltipBlack>

      {/* SELECTED DAY */}
      <Typography
        variant='body2'
        className={classes.selectedDay}
      >
        Today
      </Typography>

      {/* NEXT DAY ICON */}
      <CustomTooltipBlack
        title='Next Day'
        placement='top'
      >
        <IconButton className={classes.iconButton}>
          <IconArrowRight className={classes.iconAction}/>
        </IconButton>
      </CustomTooltipBlack>
    </Box>
  )
}

export default ContentHeader