import React, { useEffect, useRef } from 'react'
import moment from 'moment'

// CONSTANTS
import { colors } from 'constants/colors'

// ECHARTS
import * as echarts from 'echarts'

// MUIS
import Box from '@mui/material/Box'

// STYLES
import useStyles from './chartTripsUseStyles'

// UTILS
import { 
  convertTimeStampToHourMinute,
  convertSecondToHMS, 
} from 'utils/date'

const ChartTrips = () => {
  const classes = useStyles()

  const chartRef = useRef()

  const data = []
  const dataCount = 10
  const startTime = +moment().startOf('day').toDate()
  const endTime = +moment().add(1, 'days').startOf('days').toDate()
  const categories = ['Device']
  const types = [
    {
      name: 'PIT',
      color: colors.blue2,
    },
    {
      name: 'HAUL',
      color: colors.green2,
    },
    {
      name: 'ABP',
      color: colors.yellow1,
    },
    {
      name: 'BKS',
      color: colors.red3,
    },
    {
      name: 'IP',
      color: colors.blue3,
    },
    {
      name: 'LKCT',
      color: colors.orange2,
    },
    {
      name: 'Workshop',
      color: colors.purple,
    },
  ]

  const renderItem = (params, api) => {
    let categoryIndex = api.value(0)
    let start = api.coord([api.value(1), categoryIndex])
    let end = api.coord([api.value(2), categoryIndex])
    let height = api.size([0, 1])[1] * 0.6
    let rectShape = echarts.graphic.clipRectByRect(
      {
        x: start[0],
        y: start[1] - height / 2,
        width: end[0] - start[0],
        height: height,
      },
      {
        x: params.coordSys.x,
        y: params.coordSys.y,
        width: params.coordSys.width,
        height: params.coordSys.height,
      },
    )
    return (
      rectShape && {
        type: 'rect',
        transition: ['shape'],
        shape: rectShape,
        style: api.style(),
      }
    )
  }

  categories.forEach((category, index) => {
    let baseTime = startTime
    for (let i = 0; i < dataCount; i++) {
      let typeItem = types[Math.round(Math.random() * (types.length - 1))]
      let duration = Math.round(Math.random() * 10000000)
      data.push({
        name: typeItem.name,
        value: [index, baseTime, (baseTime += duration), duration],
        itemStyle: {
          normal: {
            color: typeItem.color,
          },
        },
      })
      baseTime += Math.round(Math.random() * 5000000)
    }
  })

  const chartOption = {
    tooltip: {
      formatter: (params) => {
        return params.marker + params.name + ': ' + convertSecondToHMS(params.value[3] / 1000)
      }
    },
    dataZoom: [
      {
        type: 'slider',
        filterMode: 'weakFilter',
        showDataShadow: false,
        top: 400,
        labelFormatter: '',
      },
      {
        type: 'inside',
        filterMode: 'weakFilter',
      },
    ],
    grid: {
      top: 20,
      left: 20,
      right: 40,
      bottom: 20,
      containLabel: true,
    },
    xAxis: {
      min: startTime,
      max: endTime,
      scale: true,
      position: 'top',
      maxInterval: 3600000,
      axisLabel: {
        formatter: (val) => {
          return convertTimeStampToHourMinute(val)
        },
      },
    },
    yAxis: {
      data: categories,
    },
    series: [
      {
        type: 'custom',
        renderItem: renderItem,
        itemStyle: {
          opacity: 1,
        },
        encode: {
          x: [1, 2],
          y: 0,
        },
        data: data,
      },
    ],
  }

  useEffect(() => {
    let chart
    if (chartRef.current !== null) chart = echarts.init(chartRef.current)

    const resizeChart = () => {
      chart?.resize()
    }
    window.addEventListener('resize', resizeChart)

    if (chartRef.current !== null) {
      const chart = echarts.getInstanceByDom(chartRef.current)
      chart.setOption(chartOption)
    }

    return () => {
      chart?.dispose()
      window.removeEventListener('resize', resizeChart)
    }
  }, [])

  return (
    <Box className={classes.chartContainer}>
      {/* CHART */}
      <Box
        ref={chartRef}
        className={classes.chart}
      />
    </Box>
  )
}

export default ChartTrips