import React from 'react'

// CONSTANTS
import { values } from 'constants/values'

// MUIS
import Alert from '@mui/material/Alert'
import MuiSnackbar from '@mui/material/Snackbar'
import { green } from '@mui/material/colors'

const Snackbar = (props) => {
  const {
    open,
    setToast,
    severity,
    message,
  } = props

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setToast(values.initialSnackbarObject)
  }

  const getBackgroundColor = (inputSeverity) => {
    if(inputSeverity === 'success') return green[500]
  }

  return (
    <MuiSnackbar 
      open={open} 
      autoHideDuration={6000} 
      onClose={handleToastClose}
      anchorOrigin={{ 
        vertical: 'top', 
        horizontal: 'right', 
      }}
    >
      <Alert 
        elevation={6}
        variant='filled'
        onClose={handleToastClose}
        severity={severity}
        sx={{
          '&.MuiPaper-root': {
            backgroundColor: getBackgroundColor(severity),
          },
        }}
      >
        {message}
      </Alert>
    </MuiSnackbar>
  )
}

export default Snackbar