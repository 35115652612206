// CONSTANTS
import { colors } from 'constants/colors'

// MUIS
import { alpha } from '@mui/material/styles'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  panelContainer: {
    position: 'absolute',
    zIndex: 2,
    backgroundColor: 'white',
    borderRadius: 4,
    left: 20,
    top: 20,
    width: 372,
    height: 558,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: theme.shadows[4],
  },
  panelHeader: {
    display: 'flex',
    alignItems: 'center',
    height: 56,
  },
  iconToggle: {
    width: 36,
    height: 36,
    marginLeft: 12,
  },
  panelTitle: {
    marginLeft: 8,
    fontWeight: 600,
  },
  checkboxAll: {
    marginLeft: 8,
  },
  searchInput: {
    width: '100%',
    marginLeft: 6,
  },
  searchIcon: {
    marginRight: 16,
    color: colors.iconDark,
    cursor: 'pointer',
  },
  deviceList: {
    overflowY: 'auto',
    flex: 1,
  },
  deviceItem: {
    display: 'flex',
    alignItems: 'center',
    height: 45,
    padding: 0,
    borderRadius: 4,
    margin: '0px 4px',
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.15),
    },
  },
  deviceItemCheckbox: {
    marginLeft: 4,
  },
  deviceItemName: {
    marginLeft: 6,
  },
}))

export default useStyles