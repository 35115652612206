import React, { useState, useEffect, useRef } from 'react'

// COMPONENTS
import Chart from './Chart/Chart'
import ChangeView from './ChangeView/ChangeView'
import LoadingBox from 'components/LoadingBox/LoadingBox'
import DataGridFilters from 'components/DataGridFilters/DataGridFilters'
import PageHeader from 'components/PageHeader/PageHeader'

// CUSTOM COMPONENTS
import CustomDataGridPro from 'components/Customs/CustomDataGridPro'

// MUIS
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

// SERVICES
import { 
  getFuelReportList, 
  formatFuelReportListOutput, 
} from 'services/fuelReportServices'

// STYLES
import pageStyles from 'styles/layoutMainPageUseStyles'
import useStyles from './fuelReportUseStyles'

// UTILS
import {
  getStartDateFromLocalStorage,
  getEndDateFromLocalStorage,
} from 'utils/localStorage'
import { 
  exportFuelReportListToExcel, 
  exportChartToImage,
} from 'utils/fuelReportUtil'

const FuelReport = () => {
  const pageClasses = pageStyles()
  const classes = useStyles()

  const chartRef = useRef()

  const tabItemList = [
    {
      title: 'All Shifts',
      param: 'all',
    },
    {
      title: 'Day Shift',
      param: 'day',
    },
    {
      title: 'Night Shift',
      param: 'night',
    },
  ]

  const initialDateRangeValue = [ 
    getStartDateFromLocalStorage(), 
    getEndDateFromLocalStorage() 
  ]

  const initialColumns = [
    {
      field: 'deviceName',
      headerName: 'CN',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'fuelReportPerHour',
      headerName: 'Fuel Consumption Rate L/h',
      flex: 1,
      minWidth: 175,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        let circleClassName = ''
        if(params.value.toLowerCase() === 'high') {
          circleClassName = `${classes.columnStatusCircle} ${classes.columnStatusCircleRed}`
        }
        else if(params.value.toLowerCase() === 'low') {
          circleClassName = `${classes.columnStatusCircle} ${classes.columnStatusCircleGreen}`
        }

        return (
          <Box className={classes.columnStatus}>
            {/* CIRCLE */}
            <Box
              component='div'
              className={circleClassName}
            />

            {/* TEXT */}
            <Typography
              variant='subtitle2'
              className={classes.columnStatusText}
            >
              {params.value.toLowerCase()}
            </Typography>
          </Box>
        )
      },
    },
  ]

  const [ selectedView, setSelectedView ] = useState('table')
  const [ selectedTab, setSelectedTab ] = useState(0)
  const [ dateRangeValue, setDateRangeValue ] = useState(initialDateRangeValue)
  const [ search, setSearch ] = useState('')
  const [ tableData, setTableData ] = useState([])
  const [ chartData, setChartData ] = useState({})
  const [ filteredTableData, setFilteredTableData ] = useState([])
  const [ isLoading, setIsLoading ] = useState(false)
  const [ selectedColumnList, setSelectedColumnList ] = useState(initialColumns)

  const handleDownloadIconButtonClick = () => {
    if(selectedView === 'table') {
      exportFuelReportListToExcel(
        tableData,
        dateRangeValue[0].getTime(), 
        dateRangeValue[1].getTime(),
        tabItemList[selectedTab].title,
      )
    }
    else if(selectedView === 'chart') {
      exportChartToImage(
        chartRef, 
        dateRangeValue[0].getTime(), 
        dateRangeValue[1].getTime(),
        tabItemList[selectedTab].title,
      )
    }
  }

  const loadFuelReportListData = async () => {
    setIsLoading(true)

    const resultFuelReportList = await getFuelReportList(
      dateRangeValue[0].getTime(),
      dateRangeValue[1].getTime(),
      tabItemList[selectedTab].param,
    )

    if(resultFuelReportList.status === 200) {
      const formatedFuelReportList = await formatFuelReportListOutput(resultFuelReportList.data)
      setTableData(formatedFuelReportList.fuelConsumedDetails)
      setChartData(formatedFuelReportList)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    loadFuelReportListData()
  }, [dateRangeValue, selectedTab])

  useEffect(() => {
    if(search !== '' && selectedView === 'table') {
      const newTableData = tableData.filter(item => {
        if(
          item.deviceName.toLowerCase().includes(search.toLowerCase()) ||
          String(item.fuelReportPerHour).includes(search) ||
          item.status.toLowerCase().includes(search.toLowerCase())
        ) return item
      })
      setFilteredTableData(newTableData)
    }
  }, [search])

  return (
    <Box className={pageClasses.pageRoot}>
      {/* PAGE HEADER */}
      <PageHeader
        isAddButtonAvailable={false}
        onAddButtonIsClicked={null}
        isBackButtonAvailable={false}
        targetBackUrl={null}
        title='Fuel Report'
        isSearchAvailable={true}
        search={search}
        setSearch={setSearch}
        isExtraComponentAvailable={true}
        extraComponent={
          <ChangeView
            selectedView={selectedView}
            setSelectedView={setSelectedView}
          />
        }
      />

      {/* CONTENT */}
      <LoadingBox isLoading={isLoading}>
        {/* DATA GRID FILTERS */}
        <DataGridFilters
          isWithTabs={true}
          title={null}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          tabItemList={tabItemList}
          handleDownloadIconButtonClick={handleDownloadIconButtonClick}
          isWithCustomizeColumn={selectedView === 'table' ? true : false}
          columns={initialColumns}
          selectedColumnList={selectedColumnList}
          setSelectedColumnList={setSelectedColumnList}
          isWithDateRange={true}
          dateRangeValue={dateRangeValue}
          setDateRangeValue={setDateRangeValue}
        />

        {/* TABLE */}
        {selectedView === 'table' &&
        <CustomDataGridPro
          columns={selectedColumnList}
          rows={search === '' ? tableData : filteredTableData}
        />}

        {/* CHART */}
        {selectedView === 'chart' &&
        <Chart 
          chartRef={chartRef}
          chartData={chartData}
        />}
      </LoadingBox>
    </Box>
  )
}

export default FuelReport