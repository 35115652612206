// ASSETS
import IconCanbus from 'assets/images/icons/IconCanbus'
import IconFuelReport from 'assets/images/icons/IconFuelReport'
import IconInsight from 'assets/images/icons/IconInsight'
import IconPayload from 'assets/images/icons/IconPayload'
import IconRoutes from 'assets/images/icons/IconRoute'
import IconSettings from 'assets/images/icons/IconSettings'
import IconTracking from 'assets/images/icons/IconTracking'
import IconVehicle from 'assets/images/icons/IconVehicle'

export const drawerNavigationList = [
  {
    title: 'Insight',
    icon: IconInsight,
    path: '/',
  },
  {
    title: 'Tracking',
    icon: IconTracking,
    path: '/tracking',
  },
  {
    title: 'Routes',
    icon: IconRoutes,
    path: '/routes',
  },
  {
    title: 'Canbus',
    icon: IconCanbus,
    path: '/canbus',
  },
  {
    title: 'Fuel Reports',
    icon: IconFuelReport,
    path: '/fuel-report',
  },
  {
    title: 'Payload',
    icon: IconPayload,
    path: '/payload',
  },
  {
    title: 'Vehicles',
    icon: IconVehicle,
    path: '/vehicles',
  },
]

export const bottomNavigationList = [
  {
    title: 'Settings',
    icon: IconSettings,
    path: '/settings',
  },
]