// CONTSTANTS
import { colors } from 'constants/colors'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  cardItem: {
    padding: 16,
    height: 130,
  },
  cardItemLast: {
    flexDirection: 'row',
  },
  cardItemHalf: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 12,
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 2,
    height: 30,
    width: 30,
  },
  icon: {
    height: 16,
    width: 16,
  },
  title: {
    fontWeight: 600,
    marginLeft: 12,
    marginRight: 'auto',
  },
  fontBold: {
    fontWeight: 600,
  },
  arrow: {
    marginLeft: 4,
    fontSize: 16,
  },
  arrowGreen: {
    color: colors.green4,
  },
  arrowRed: {
    color: colors.red2,
  },
  lastTimestamp: {
    color: theme.palette.text.secondary,
    marginTop: 4,
  },
}))

export default useStyles