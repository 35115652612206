// CONTSTANTS
import { 
  colors, 
  entLacakColors, 
} from 'constants/colors'

// MUI ICONS
import IconLocalShipping from '@mui/icons-material/LocalShipping'
import IconTimelapse from '@mui/icons-material/Timelapse'
import IconVerified from '@mui/icons-material/Verified'

// OVERALL CARDS

export const overallCardList = [
  {
    icon: IconLocalShipping,
    color: colors.blue1,
    title: 'Ongoing',
    changes: 10,
    value: 1625,
    lastTimestamp: 'Since last week',
  },
  {
    icon: IconVerified,
    color: colors.green1,
    title: 'Available',
    changes: -5,
    value: 303,
    lastTimestamp: 'Since last week',
  },
  {
    icon: IconTimelapse,
    color: colors.red2,
    title: 'Maintenance',
    changes: 10,
    value: 1625,
    lastTimestamp: 'Since last week',
  },
]

export const overallPieChartConfig = {
  chart: { 
    type: 'radialBar',
    toolbar: {
      show: false
    },
  },
  colors: [ colors.green4 ],
  plotOptions: {
    radialBar: {
      hollow: { 
        size: '40%', 
      },
      track: {
        background: colors.grey,
        strokeWidth: '100%',
      },
      dataLabels: {
        show: true,
        name: {
          show: false,
        },
        value: {
          formatter: (val) => {
            return parseInt(val) + ' %'
          },
          offsetY: 5,
          color: 'black',
          fontSize: '14px',
          show: true,
        },
      },
    },
  },
  stroke: {
    lineCap: 'round',
  },
}

// STATISTICS REPORT CARD

export const statisticsReportCardList = (inputLength) => {
  return [
    {
      title: 'Total Cycle',
      changes: 10,
      value: 155,
      lastTimestamp: 'Since last week',
      color: colors.yellow1,
      data: Array.from({ length: inputLength }, (item, index) => Math.floor((Math.random() * 100) + 1)),
    },
    {
      title: 'Total Weight Transported',
      changes: 10,
      value: 2980,
      lastTimestamp: 'Since last week',
      color: colors.orange2,
      data: Array.from({ length: inputLength }, (item, index) => Math.floor((Math.random() * 100) + 1)),
    },
    {
      title: 'Truck Average Payload',
      changes: 10,
      value: 95,
      lastTimestamp: 'Since last week',
      color: colors.purple,
      data: Array.from({ length: inputLength }, (item, index) => Math.floor((Math.random() * 100) + 1)),
    },
  ]
}

export const statisticsReportChartSettings = (inputXAxisCategories) => {
  return {
    series: statisticsReportCardList(inputXAxisCategories.length).map(item => {
      return {
        name: item.title,
        data: item.data,
      }
    }),
    options: {
      chart: {
        type: 'line',
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      colors: statisticsReportCardList(inputXAxisCategories.length).map(item => item.color),
      dataLabels: {
        enabled: false,
      },
      grid: {
        borderColor: entLacakColors.backgroundPage,
      },
      stroke: {
        width: [ 3, 3, 3, ],
        curve: 'straight',
        dashArray: [ 0, 0, 0, ],
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: inputXAxisCategories,
      },
    },
  }
}

// COLUMN CHART CARDS

export const columnChartList = [
  {
    title: 'Availability Rate',
    color: colors.green4,
  },
  {
    title: 'Fuel Consumption (L/h)',
    color: colors.red2,
  },
  {
    title: 'Utilization Rate',
    color: colors.green4,
  },
  {
    title: 'Total Range (km)',
    color: colors.red2,
  },
  {
    title: 'Truck Load',
    color: colors.green4,
  },
  {
    title: 'Engine Hours (h)',
    color: colors.red2,
  },
]

export const columnChartOptions = (
  inputTitle,
  inputColor,
  inputXAxisCategories,
) => {
  return {
    series: [
      {
        name: inputTitle,
        data: Array.from(
          { length: inputXAxisCategories.length }, 
          (item, index) => Math.floor((Math.random() * 100) + 1)
        ),
      },
    ],
    options: {
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: '50%',
          borderRadius: 4,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: false,
      },
      xaxis: {
        categories: inputXAxisCategories,
      },
      fill: {
        opacity: 1,
        colors: [ inputColor ],
      },
    },
  }
}