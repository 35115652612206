// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  chartContainer: {
    width: '100%',
    height: 'fit-content',
    flexShrink: 0,
  },
  chart: {
    width: '100%',
    height: 150,
  },
}))

export default useStyles