import XLSX from 'xlsx'

// UTILS
import { 
  convertTimeStampToDateOnly, 
  convertTimeStampToDateAndTimeGMT7,
  convertValueTo1DecimalPoint,
} from 'utils/date'

export const exportCanbusListToExcel = (
  inputCanbusListData,
  inputBeginTime,
  inputEndTime,
) => {
  const sheetCanbusData = inputCanbusListData.map((item, index) => {
    return {
      'No': index + 1,
      'DT Number': item.deviceName,
      'Date': convertTimeStampToDateAndTimeGMT7(item.begin),
      'Fuel Consumed (L/h)': item.fuelConsumedHourly,
      'HM': item.hm,
      'HM Start': item.hmStart,
      'HM End': item.hmEnd,
      'ODO Start (Km)': convertValueTo1DecimalPoint(item.mileageStartHourly),
      'ODO End (Km)': convertValueTo1DecimalPoint(item.mileageEndHourly),
      'Engine Load Avg': item.engineLoadAvgHourly,
      'Engine Load Max': item.engineLoadMaxHourly,
      'Engine Load Min': item.engineLoadMinHourly,
      'Temperature Avg': item.engineTemperatureAvgHourly,
      'Temperature Max': item.engineTemperatureMaxHourly,
      'Temperature Min': item.engineTemperatureMinHourly,
      'RPM Avg': item.engineRpmAvgHourly,
      'RPM Max': item.engineRpmMaxHourly,
      'RPM Min': item.engineRpmMinHourly,
      'Throttle Pedal Level Avg': item.pedalLevelAvgHourly,
      'Throttle Pedal Level Max': item.pedalLevelMaxHourly,
      'Throttle Pedal Level Min': item.pedalLevelMinHourly,
    }
  })

  const sheetCanbus = XLSX.utils.json_to_sheet(sheetCanbusData)
  
  const workBook = XLSX.utils.book_new()

  XLSX.utils.book_append_sheet(workBook, sheetCanbus, 'Canbus')

  XLSX.writeFile(workBook, `PPA Canbus ${convertTimeStampToDateOnly(inputBeginTime)} - ${convertTimeStampToDateOnly(inputEndTime)}.xlsx`)
}