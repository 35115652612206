import React, { useState, useEffect } from 'react'

// MOMENT
import moment from 'moment'

// MUIS
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconBattery50 from '@mui/icons-material/Battery50'
import IconClear from '@mui/icons-material/Clear'
import IconLocationOn from '@mui/icons-material/LocationOn'
import IconSignalCellular2Bar from '@mui/icons-material/SignalCellular2Bar'

// REACT GEOCODE
import Geocode from 'react-geocode'

// STYLES
import useStyles from './panelDeviceDetailUseStyles'

const PanelDeviceDetail = (props) => {
  const {
    panelDeviceDetail, 
    setPanelDeviceDetail,
    isDevicePanelShown,
  } = props

  const classes = useStyles()

  const infoLastUpdated = panelDeviceDetail.state.last_update ? moment(panelDeviceDetail.state.last_update, 'YYYY-MM-DD hh:mm:ss').fromNow() : '-'
  const infoBatteryLevel = panelDeviceDetail.state.battery_level ? `${panelDeviceDetail.state.battery_level}%` : 'null%'
  const infoSignalLevel = panelDeviceDetail.state.gsm.signalLevel ? `${panelDeviceDetail.state.gsm.signalLevel}%` : 'null%'

  const dummyDetailInfoList = [
    {
      title: 'Speed',
      value: panelDeviceDetail.state.gps.speed,
      unit: ' Km/h',
    },
    {
      title: 'Mileage',
      value: panelDeviceDetail.state.lastMileage ? panelDeviceDetail.state.lastMileage : 'null',
      unit: ' Km',
    },
    {
      title: 'Hour Meter',
      value: panelDeviceDetail.state.lastMotorHours ? panelDeviceDetail.state.lastMotorHours : 'null',
      unit: '',
    },
    {
      title: 'Fuel Consumption',
      value: panelDeviceDetail.state.lastFuelConsumed ? panelDeviceDetail.state.lastFuelConsumed : 'null',
      unit: ' L/h',
    },
  ]

  const [ address, setAddress ] = useState('')
  const [ detailInfoList, setDetailInfoList ] = useState([])

  const getAddressFromLocation = (inputLatitude, inputLongitude) => {
    Geocode.fromLatLng(inputLatitude, inputLongitude)
    .then(
      (response) => {
        const formattedAddress = response.results[0].formatted_address
        setAddress(formattedAddress)
      },
      (error) => {
        setAddress('No Address Found')
      }
    )
  }

  const getLatitudeLongitudeAndAltitudeText = (inputLatitude, inputLongitude, inputAltitude) => {
    if (inputLatitude && inputLongitude && inputAltitude) return `${inputLatitude}, ${inputLongitude} (altitude ${inputAltitude} meters)`
    else if (inputLatitude && inputLongitude && !inputAltitude) return `${inputLatitude}, ${inputLongitude} (no altitude found)`
    else return 'No location and altitude found'
  }
  
  useEffect(() => {
    if(panelDeviceDetail) getAddressFromLocation(panelDeviceDetail.state.gps.location.lat, panelDeviceDetail.state.gps.location.lng)

    setDetailInfoList(dummyDetailInfoList)
  }, [panelDeviceDetail])

  return (
    <Box
      className={
        isDevicePanelShown ?
        classes.panelContainer :
        `${classes.panelContainer} ${classes.panelContainerMove}`
      }
    >
      {/* HEADER */}
      <Box className={classes.panelHeader}>
        {/* PANEL TITLE */}
        <Typography
          variant='h6'
          className={classes.panelTitle}
        >
          {panelDeviceDetail.label}
        </Typography>

        {/* CLOSE ICON */}
        <IconButton 
          className={classes.iconClose}
          onClick={() => setPanelDeviceDetail(null)}
        >
          <IconClear/>
        </IconButton>
      </Box>

      <Divider/>

      {/* CONTENT */}
      <Box className={classes.panelContent}>
        {/* LOCATION */}
        <Box className={classes.contentLocation}>
          {/* ICON */}
          <IconLocationOn className={classes.contentLocationIcon}/>

          <Box>
            {/* LOCATION TITLE */}
            <Typography variant='body2'>
              Location
            </Typography>

            {/* LOCATION LAST UPDATE */}
            <Typography
              variant='caption'
              className={classes.contentLastUpdated}
            >
              {infoLastUpdated}
            </Typography>
          </Box>
        </Box>

        {/* INDENTED CONTENT */}
        <Box className={classes.contentIndented}>
          {/* BATTERY */}
          <Box className={classes.contentBatteryAndSignal}>
            {/* ICON */}
            <IconBattery50 className={classes.contentBatteryAndSignalIcon}/>

            {/* VALUE */}
            <Typography variant='body2'>
              {`Battery Level: ${infoBatteryLevel}`}
            </Typography>
          </Box>

          {/* SIGNAL */}
          <Box className={classes.contentBatteryAndSignal}>
            {/* ICON */}
            <IconSignalCellular2Bar className={classes.contentBatteryAndSignalIcon}/>

            {/* VALUE */}
            <Typography variant='body2'>
              {`Signal Level: ${infoSignalLevel}`}
            </Typography>
          </Box>

          {/* ADDRESS */}
          <Typography 
            variant='body2'
            className={classes.contentAddress}
          >
            {address}
          </Typography>

          {/* LATITUDE, LONGITUDE, AND ALTITUDE */}
          <Typography 
            variant='caption'
            className={classes.textSecondary}
          >
            {getLatitudeLongitudeAndAltitudeText(
              panelDeviceDetail.state.gps.location.lat,
              panelDeviceDetail.state.gps.location.lng,
              panelDeviceDetail.state.gps.alt,
            )}
          </Typography>

          {/* DETAIL INFO */}
          {detailInfoList.map((item, index) => (
            <Box 
              key={index}
              className={classes.contentDetailInfo}
            >
              {/* TITLE */}
              <Typography 
                variant='caption'
                className={classes.textSecondary}
              >
                {item.title}
              </Typography>

              {/* VALUE AND UNITS */}
              <Typography 
                variant='caption'
                className={classes.textSecondary}
              >
                {`${item.value}${item.unit}`}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>      
    </Box>
  )
}

export default PanelDeviceDetail