// MUIS
import SvgIcon from '@mui/material/SvgIcon'

const IconPayload = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M12.1202 2.11666V3.03333V11.8169L18.9773 4.95797L18.3292 4.30986C16.9756 2.95619 15.0994 2.11666 13.0369 2.11666H12.1202ZM10.2869 3.07988C5.66049 3.54004 2.03333 7.4535 2.03333 12.2C2.03333 17.2545 6.14549 21.3667 11.2 21.3667C16.2545 21.3667 20.3667 17.2545 20.3667 12.2C20.3667 10.4739 19.8786 8.86288 19.0454 7.48238L17.7044 8.82516C18.2315 9.83624 18.5333 10.9827 18.5333 12.2C18.5333 13.8916 17.9506 15.4463 16.9847 16.6884L13.4129 13.1167L12.1167 14.4129L15.6884 17.9847C14.4463 18.9506 12.8916 19.5333 11.2 19.5333C7.46777 19.5333 4.38288 16.728 3.92932 13.1167H10.2833V11.2833H3.93111C4.34747 7.97297 6.97559 5.34355 10.2869 4.92932V3.07988ZM13.9536 4.1344C14.8126 4.2799 15.5804 4.59706 16.2596 5.0833L13.9536 7.3875V4.1344Z"/>
    </SvgIcon>
  )
}

export default IconPayload