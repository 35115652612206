import React from 'react'
import ReactDOMServer from 'react-dom/server'

// CONSTANTS
import customTheme from 'constants/customTheme'

// LEAFLET
import { Marker } from 'react-leaflet'
import L from 'leaflet'

// MUIS
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { ThemeProvider } from '@mui/material/styles'

// MUI ICONS
import IconNearMe from '@mui/icons-material/NearMe'

// STYLES
import useStyles from './customMarkerUseStyles'

const CustomMarker = (props) => {
  const { 
    item,
    setPanelDeviceDetail,
  } = props

  const classes = useStyles()

  const getMarkerComponent = () => {
    if(item.state.movement_status === 'Moving') {
      return (
        <Box className={classes.markerContainer}>
          {/* ICON */}
          <IconNearMe className={classes.markerMoving}/>
  
          {/* LABEL */}
          <Typography 
            variant='caption'
            className={classes.markerLabel}
          >
            {item.label}
          </Typography>
        </Box>
      )
    }
    else {
      return (
        <Box className={classes.markerContainer}>
          {/* ICON */}
          <Avatar className={classes.markerParking}>
            P
          </Avatar>
  
          {/* LABEL */}
          <Typography 
            variant='caption'
            className={classes.markerLabel}
            display='inline'
          >
            {item.label}
          </Typography>
        </Box>
      )
    }
  }


  return (
    <Marker
      position={[ item.state.gps.location.lat, item.state.gps.location.lng ]}
      eventHandlers={{
        click: (event) => setPanelDeviceDetail(item),
      }}
      icon={L.divIcon({
        className: 'custom-icon',
        html: ReactDOMServer.renderToString(
          <ThemeProvider theme={customTheme}>
            {getMarkerComponent()}
          </ThemeProvider>
        )
      })}
    />
  )
}

export default CustomMarker