// CONTSTANTS
import { colors } from 'constants/colors'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 24,
    padding: 16,
    flex: 0,
  },
  textBold: {
    fontWeight: 600,
  },
  title: {
    marginBottom: 16,
  },
  cardItem: {
    padding: 12,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 2,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
  },
  cardItemHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
  },
  cardItemChanges: {
    marginLeft: 'auto',
  },
  cardItemArrow: {
    marginLeft: 4,
    fontSize: 14,
  },
  cardItemArrowGreen: {
    color: colors.green4,
  },
  cardItemArrowRed: {
    color: colors.red2,
  },
  cardItemLastTimestamp: {
    color: theme.palette.text.secondary,
    marginTop: 4,
  },
  chartContainer: {
    marginTop: 24,
  },
}))

export default useStyles